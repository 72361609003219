import { gql, useQuery } from "@apollo/client";
import {
  usePoliciesDocumentsCall,
  usePracticesDocumentsCall,
} from "../../../hooks";
import { Practice } from "../../../types";

type PracticesCollectionData = {
  practices: Practice[];
};

const fetchPracticesDocuments = gql`
  query ($limit: Int) {
    practiceDocuments(limit: $limit) {
      createdAt
      filename
      id
      path
    }
  }
`;

// {
//   policyDocuments(limit: 2) {
//     collection {
//       createdAt
//       filename
//       id
//       path
//     }
//     metadata {
//       currentPage
//       limitValue
//       totalCount
//       totalPages
//     }
//   }
// createdAt
// filename
// id
// path
// }
const fetchPoliciesDocuments = gql`
  query ($limit: Int, $page: Int) {
    policyDocuments(limit: $limit, page: $page) {
      collection {
        createdAt
        filename
        id
        path
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

function useDataCall({ module, limit }: any) {
  const queryCall =
    module === "policies" ? fetchPoliciesDocuments : fetchPracticesDocuments;
  const data = useQuery<PracticesCollectionData>(queryCall, {
    fetchPolicy: "cache-and-network",
    variables: limit,
  }) as any;

  return data || {};
}

export { useDataCall };
