import { Container, Grid, Link, Paper, Stack, Typography } from "@mui/material";
import {
  usePracticesDocumentsCall,
  usePublicPracticesCall,
} from "../../@lib/hooks";
import { Card, LatestReport } from "../../@lib/components";

function Practices() {
  const { practiceDocuments } = usePracticesDocumentsCall({
    limit: 5,
  }) as any;
  const { practices } = usePublicPracticesCall({ limit: 4 });

  return (
    <>
      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3, mb: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">Practices</Typography>
            <Link href="/practices/all" underline="none" color="textSecondary">
              View all
            </Link>
          </Stack>
        </Paper>

        <Grid container spacing={2}>
          {practices?.map((elem, i) => (
            <Grid key={`${i}`} item xs={12} md={6} lg={3}>
              <Card
                status={elem?.status}
                id={`practices/${elem?.id}`}
                title={elem?.title}
                description={elem?.description as any}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      <LatestReport items={practiceDocuments} path="/practices/viewAllReports" />
    </>
  );
}

export { Practices };
