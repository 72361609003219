import { useMemo } from "react";
import { useQuery, gql } from "@apollo/client";
import { Practice } from "../../types";

export type PracticesCollectionData = {
  practices: { collection: Practice[]; metadata: {} };
};

const fetchPracticesQueryDocument = gql`
  query FetchPracticesQuery($limit: Int, $page: Int) {
    practices(limit: $limit, page: $page) {
      collection {
        country {
          name
        }
        createdAt
        date
        description
        document
        id
        link
        picture
        practiceCategory {
          name
        }
        status
        theme {
          id
          title
        }
        title
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

const usePublicPracticesCall = (limit: any, currTabIndex?: any) => {
  const { data, loading } = useQuery<PracticesCollectionData>(
    fetchPracticesQueryDocument,
    { fetchPolicy: "cache-and-network", variables: limit }
  );

  const dist = useMemo(() => {
    const dist = {
      chainActorPractices: [] as Practice[],
      normesdegestiondesboues: [] as Practice[],
      normesdegestiondeseauxusees: [] as Practice[],
      finance: [] as Practice[],
      regulatory: [] as Practice[],
      community: [] as Practice[],
    };

    data?.practices?.collection?.forEach((p) => {
      switch (p?.practiceCategory?.name?.toLowerCase()) {
        case "normes de gestion des eaux usées":
          dist.normesdegestiondeseauxusees.push(p);
          break;
        case "normes de gestion des boues":
          dist.normesdegestiondesboues.push(p);
          break;
        case "pratiques des acteurs de la chaine":
          dist.chainActorPractices.push(p);
          break;
        case "normes":
          dist.finance.push(p);
          break;
        case "pratiques réglementaires":
          dist.regulatory.push(p);
          break;
        case "pratiques communautaires":
        default:
          dist.community.push(p);
          break;
      }
    });

    return dist;
  }, [data]);

  const practices = (() => {
    switch (currTabIndex) {
      case "normesdegestiondesboues":
        return dist.normesdegestiondesboues;
      case "normesdegestiondeseauxusées":
        return dist.normesdegestiondeseauxusees;
      case "chainActorPractices":
        return dist.chainActorPractices;
      case "finance":
        return dist.finance;
      case "regulatory":
        return dist.regulatory;
      case "community":
        return dist.community;
      case 0:
      default:
        return data?.practices?.collection || [];
    }
  })();

  return {
    practices,
    dist: {
      all: data?.practices?.collection || [],
      ...dist,
    },
    loading,
    metadata: data?.practices?.metadata,
  };
};

export { usePublicPracticesCall };
