function imageCheck({ file, setMessage, setAttachmentDetails }: any) {
  const img = new Image();
  const objectUrl = URL.createObjectURL(file);

  img.onload = function () {
    const { width, height } = img;

    if (width <= 800 && height <= 400) {
      setAttachmentDetails(file);
      setMessage("");
    } else {
      setAttachmentDetails({});
      setMessage("Image exceeds the maximum dimensions of 800x400px.");
    }

    URL.revokeObjectURL(objectUrl);
  };

  img.src = objectUrl;
}

function onlyPDF({ file, setAttachmentDetails, setMessage }: any) {
  const fileSizeInMB = file.size / (1024 * 1024);
  if (fileSizeInMB <= 50) {
    setMessage("");
    setAttachmentDetails(file);
  } else {
    setAttachmentDetails({});
    setMessage("The file size should be less than or equal to 50MB.");
  }
}

export { imageCheck, onlyPDF };
