import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import CTAContainer from "../../@lib/components/@common/CTAContainer";
import PersonCard from "../../@lib/components/PersonCard";
import StatusTag from "../../@lib/components/@common/StatusTag";
import { useAuthSession } from "../../@lib/components/AuthSessionProvider";
import { Listings } from "../components";
import { Header } from "../../@lib/components";
import { usePeoplesCall } from "../../@lib/hooks";
import {
  ConditionalRenderer,
  PublicAndPolicyFilters,
} from "../../@lib/components";
import { styled } from "@mui/material/styles";
import { useMemo, useState } from "react";
import { useFilters } from "../helpers";
import { isConstructorDeclaration } from "typescript";
import PolicyFilters from "../../@lib/components/PolicyFilters";

const personCardSkeleton = <Skeleton variant="rounded" height={420} />;

const StatusWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  backgroundColor: "#ffffff",
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  boxShadow: theme.shadows[3],
}));

function PeopleHome() {
  const [currTab, setCurrTab] = useState("all");

  const { user } = useAuthSession();
  const isLoggedIn = !!user;

  const location = useLocation();
  const isPrivate = location?.pathname?.includes("/submission/people");

  const { data } = usePeoplesCall({ isPrivate, limit: { limit: 8 }, user });

  const { tabsData, people = [] }: any = useFilters({ isPrivate, data });

  const filteredDataResults = useMemo(() => {
    if (currTab === "all") return people;
    return people?.filter(
      (item: any) => item.status?.toLowerCase() === currTab
    );
  }, [people, currTab]);

  const modifiedData = isPrivate ? filteredDataResults : data?.people;

  return (
    <Box pb={4}>
      <Header title="People" subtitle="NGO Detail will goes here, we can utilize double line text as well, No image required due to good UX" />

      <Container sx={{ my: 3 }}>
        <ConditionalRenderer condition={isPrivate}>
          <PublicAndPolicyFilters
            currTab={currTab}
            setCurrTab={setCurrTab}
            listItems={tabsData}
            heading="List of Peoples data"
          />
        </ConditionalRenderer>
        <ConditionalRenderer condition={!isPrivate}>
          <Paper
            component={Container}
            sx={{
              py: 1,
              mb: 2,
              backgroundColor: "primary.main",
              color: "#ffffff",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle1">People</Typography>
              <Link
                component={RouterLink}
                to="all"
                underline="none"
                color="inherit"
              >
                View all
              </Link>
            </Stack>
          </Paper>
          <Container sx={{ my: 5 }}>
            <Stack direction="row" gap={2} alignItems="center">
              <Typography variant="body2" color="textSecondary">
                Filter by:
              </Typography>
              <PolicyFilters />
            </Stack>
          </Container>
        </ConditionalRenderer>

        <Grid container spacing={3}>
          {modifiedData?.map((person: any, i: any) => (
            <Grid
              key={`people-card-${i}-${person.id}`}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <PersonCard person={person}>
                <ConditionalRenderer condition={isPrivate}>
                  <StatusWrapper>
                    <StatusTag label="Approved" status="approved" />
                  </StatusWrapper>
                </ConditionalRenderer>
              </PersonCard>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Container sx={{ my: 3 }}>
        <Paper sx={{ py: 2, display: "flex", justifyContent: "center" }}>
          <Link color="textSecondary" underline="none">
            Show more
          </Link>
        </Paper>
      </Container>

      <ConditionalRenderer condition={isLoggedIn && isPrivate}>
        <Container sx={{ my: 3 }}>
          <CTAContainer sx={{ textAlign: "center" }}>
            <Typography variant="subtitle1" mb={2}>
              Add new people to the platform
            </Typography>
            <Button
              component={RouterLink}
              to="new"
              sx={{
                px: 6,
                backgroundColor: "#ffffff",
                color: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                },
              }}
            >
              Add People
            </Button>
          </CTAContainer>
        </Container>
      </ConditionalRenderer>

      <Listings title="Latest Actions" />
      <Listings title="Latest Positions" />
    </Box>
  );
}

export { PeopleHome };
