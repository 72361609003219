import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MuiListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";
import { NavLink, Link as RouterLink } from "react-router-dom";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import SvgIcon from "@mui/material/SvgIcon";
import Divider from "@mui/material/Divider";
import AppLogo from "../AppLogo";

import { ReactComponent as DashboardIcon } from "../../icons/home-icon.svg";
import { ReactComponent as PoliciesIcon } from "../../icons/policies-icon.svg";
import { ReactComponent as PracticesIcon } from "../../icons/badge-icon.svg";
import { ReactComponent as PeopleIcon } from "../../icons/people-icon.svg";
import { ReactComponent as SupportIcon } from "../../icons/barchart-icon.svg";
import { ReactComponent as SettingsIcon } from "../../icons/settings-icon.svg";
import { ReactComponent as NotificationsIcon } from "../../icons/bell-icon.svg";
import { ReactComponent as LogoutIcon } from "../../icons/logout-icon.svg";

import { useAuthSession } from "../AuthSessionProvider";
import { styled } from "@mui/material/styles";
import MySpaceMenu from "./mySpaceDropdown";
import { ConditionalRenderer } from "../conditionalRenderer";

const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  boxSizing: "border-box",
  paddingInline: theme.spacing(3),
  "&.active": {
    backgroundColor: "#F6F5F2",
    position: "relative",
  },
  "&.active:before": {
    content: `""`,
    display: "block",
    position: "absolute",
    left: 0,
    width: 4,
    height: "100%",
    backgroundColor: theme.palette.primary.main,
  },
})) as typeof MuiListItemButton;

const mainMenuItems = [
  {
    path: "/",
    label: "Dashboard",
    icon: <SvgIcon component={DashboardIcon} inheritViewBox />,
  },
  {
    path: "/indicators",
    label: "Indicators",
    icon: <SvgIcon component={PoliciesIcon} inheritViewBox />,
  },
  {
    path: "/policies",
    label: "Policies",
    icon: <SvgIcon component={PoliciesIcon} inheritViewBox />,
  },
  {
    path: "/practices",
    label: "Practices",
    icon: <SvgIcon component={PracticesIcon} inheritViewBox />,
  },
  {
    path: "/people",
    label: "People",
    icon: <SvgIcon component={PeopleIcon} inheritViewBox />,
  },
];

const MainSideNav: React.FC = () => {
  const { user, logoutUser } = useAuthSession() as any;
  const isLoggedIn = !!user;

  useEffect(() => {
    console.log("component render");
  }, []);

  return (
    <Stack sx={{ minHeight: "100%" }}>
      <Toolbar>
        <AppLogo size="medium" />
      </Toolbar>
      <Divider sx={{ opacity: 0.375, mb: 1, mx: 2 }} />
      <Box flexGrow={1}>
        <List>
          {mainMenuItems.map((item, i) => (
            <ListItemButton
              key={`${i}-${item.label}`}
              component={NavLink}
              to={item.path}
            >
              <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{
                  fontWeight: 500,
                }}
              />
            </ListItemButton>
          ))}
        </List>
      </Box>
      <Box pb={4}>
        <List>
          <ListItemButton component={NavLink} to="/support">
            <ListItemIcon sx={{ minWidth: 40 }}>
              <SvgIcon component={SupportIcon} inheritViewBox />
            </ListItemIcon>
            <ListItemText
              primary="Support"
              primaryTypographyProps={{
                fontWeight: 500,
              }}
            />
          </ListItemButton>
          <ConditionalRenderer condition={isLoggedIn}>
            <ListItemButton component={NavLink} to="/account-settings">
              <ListItemIcon sx={{ minWidth: 40 }}>
                <SvgIcon component={SettingsIcon} inheritViewBox />
              </ListItemIcon>
              <ListItemText
                primary="Account Settings"
                primaryTypographyProps={{
                  fontWeight: 500,
                }}
              />
            </ListItemButton>
           
          </ConditionalRenderer>
        </List>
        <Box pb={4}>
          <List>
            <ConditionalRenderer condition={isLoggedIn}>
            <div style={{ marginLeft: "10px" }}>
              <MySpaceMenu />
            </div>
            </ConditionalRenderer>
          </List>
        </Box>
        <Divider sx={{ opacity: 0.375, my: 1, mx: 2 }} />
        <ConditionalRenderer condition={isLoggedIn}>
          <List>
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: 40 }}>
                <SvgIcon component={NotificationsIcon} inheritViewBox />
              </ListItemIcon>
              <ListItemText
                primary="Notifications"
                primaryTypographyProps={{
                  fontWeight: 500,
                }}
              />
            </ListItemButton>
            <ListItem sx={{ paddingInline: 3 }}>
              <ListItemAvatar sx={{ minWidth: 52 }}>
                <Avatar />
              </ListItemAvatar>
              <ListItemText
                primary={`${user?.firstName} ${user?.lastName}`}
                secondary={
                  <Link
                    href="#"
                    onClick={logoutUser}
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                    color="textSecondary"
                    underline="none"
                  >
                    <SvgIcon
                      component={LogoutIcon}
                      inheritViewBox
                      fontSize="inherit"
                    />
                    <span>Logout</span>
                  </Link>
                }
                primaryTypographyProps={{
                  fontWeight: 500,
                }}
              />
            </ListItem>
          </List>
        </ConditionalRenderer>

        <ConditionalRenderer condition={!isLoggedIn}>
          <Box px={3} mt={3}>
            <Button
              component={RouterLink}
              to="/auth/login"
              variant="contained"
              fullWidth
              size="large"
              sx={{ backgroundColor: "#F6A000" }}
            >
              Sign in
            </Button>
          </Box>
        </ConditionalRenderer>
      </Box>
    </Stack>
  );
};

export default MainSideNav;
