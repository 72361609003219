import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import imagePlaceholder from "../../../@lib/images/image-placeholder.svg";

function CardAll({
  p,
  loading,
  path,
  isLoggedIn,
  handleDeletePolicy,
  handleEditPolicy,
}: any) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <CardActionArea
        component={RouterLink}
        to={path}
        disabled={loading}
        sx={{ flexBasis: 150, maxWidth: 150 }}
      >
        <CardMedia
          component="img"
          sx={{ p: 2, borderRadius: 4 }}
          height={150}
          image={imagePlaceholder}
          alt="policy papers"
        />
      </CardActionArea>
      <CardContent
        component={Stack}
        sx={{ flexGrow: 1 }}
        justifyContent="space-between"
        alignItems="flex-start"
        gap={2}
      >
        <div>
          <Typography variant="h6">{p.title}</Typography>
          <Typography component="p" variant="caption">
            {p.description}
          </Typography>
        </div>
      </CardContent>
      {isLoggedIn && p.status !== "approved" && (
        <Stack flexBasis="15%" gap={2}>
          <Button
            disabled={loading}
            size="small"
            variant="contained"
            onClick={() => handleEditPolicy(p.id)}
          >
            EDIT
          </Button>
          <Button
            disabled={loading}
            size="small"
            variant="contained"
            color="error"
            onClick={() => handleDeletePolicy(p)}
          >
            DELETE
          </Button>
        </Stack>
      )}
    </Card>
  );
}

export { CardAll };
