import React from "react";
import Typography from "@mui/material/Typography";

export type AppLogoProps = {
  size?: "small" | "medium" | "large";
};

const AppLogo: React.FC<AppLogoProps> = (props) => {
  const { size = "large" } = props;

  // TODO: This is a placeholder. Implement actual logo when it is available.
  return (
    <Typography variant={size === "large" ? "h2" : "h3"} color="primary.dark">
      <img src="/assets/images/logo.png" alt="logo" width="200" height="45" />
    </Typography>
  );
};

export default AppLogo;
