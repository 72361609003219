import { Box, Grid, Typography } from "@mui/material";
import { Listings } from "../components";
import { Header } from "../../@lib/components";
import CoverImage from "../../@lib/components/@common/CoverImage";
import placeholderImage from "../../@lib/images/person-placeholder.jpg";
import { gql, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";

const personDetailQuery = gql`
  query person($id: ID!) {
    person(id: $id) {
      id
      firstname
      lastname
      picture
      title
      createdAt
      updatedAt
      careers {
        careerActions {
          action {
            description
          }
          actionDate
        }
        careerPositions {
          position {
            description
          }
          positionDate
        }
        createdAt
        endDate
        id
        institution {
          name
        }
        personId
        role {
          name
        }
        startDate
        updatedAt
      }
    }
  }
`;
function PersonDetail() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const personId = queryParams.get("id");

  const { data, loading } = useQuery(personDetailQuery, {
    variables: { id: personId },
  });

  const {
    firstname = "",
    lastname = "",
    picture = null,
    careers = [],
  } = data?.person || {};

  return (
    <Box pb={4}>
      <Header
        title="People"
        subtitle="NGO Detail will goes here, we can utilize double line text as well, No image required due to good UX"
      />
      <Grid container spacing={2} p={2} bgcolor={"white"}>
        <Grid item xs={12} sm={6} lg={4}>
          <CoverImage
            image={picture || placeholderImage}
            height={300}
            width={278}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={5}>
          <Typography variant="h5">
            {firstname} {lastname}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "400" }} mt={1}>
            {careers?.[0]?.role?.name} position
          </Typography>
          <Typography variant="h5" mt={2}>
            About
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "400" }} mt={1}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Typography variant="h5">Country</Typography>
          <Typography variant="h6" sx={{ fontWeight: "400" }} mt={1}>
            France
          </Typography>
          <Typography variant="h5" mt={2}>
            Institution
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: "400" }} mt={1}>
            {careers?.[0]?.institution?.name}
          </Typography>
        </Grid>
      </Grid>
      <Listings
        title="Johns recent activity"
        data={careers?.[0]?.careerActions || []}
      />
    </Box>
  );
}

export { PersonDetail };
