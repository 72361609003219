
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';

import { useState, useMemo, useCallback } from 'react';
import notificationsContext, { NotificationsContext } from './context';

const NotificationsProvider:React.FC<{children:React.ReactNode}> = ({ children }) => {
  const [openState, setOpenState] = useState<{success?: boolean; error?: boolean; confirm?: boolean}>({
    success: false,
    error: false,
    confirm: false
  });
  const [message, setMessage] = useState('');
  const [error, setError] = useState<Error>();
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmation, setConfirmation] = useState<((val:boolean)=>void)|null>(null);

  const methods:NotificationsContext = useMemo(() => ({
    success: (msg) => {
      setMessage(msg);
      setOpenState((currState) => ({...currState, success: true}));
    },
    error: (err) => {
      setError(err);
      setOpenState((currState) => ({...currState, error: true}));
    },
    confirm: (msg) => new Promise ((res) => {
      setConfirmationMessage(msg);
      setConfirmation(()=>res);
      setOpenState((currState) => ({...currState, confirm: true}));
    })
  }), []);

  const handleConfirm = useCallback((val:boolean) => () => {
    confirmation && confirmation(val);
    setOpenState((currState) => ({...currState, confirm: false}));
  }, [confirmation]);

  return (
    <notificationsContext.Provider value={methods}>
      {children}
      <Snackbar
        open={openState.success}
        onClose={()=>setOpenState((currState) => ({...currState, success: false}))}
        autoHideDuration={5000}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        sx={{'&.MuiSnackbar-anchorOriginTopCenter': {top: 72}}}
        TransitionComponent={(props)=><Slide direction="down" {...props} />}
      >
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>{message}</Alert>
      </Snackbar>

      <Snackbar
        open={openState.error}
        onClose={()=>setOpenState((currState) => ({...currState, error: false}))}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        TransitionComponent={(props)=><Slide direction="down" {...props} />}
      >
        <Alert severity="error" variant="filled" sx={{ width: '100%' }}>Error: {error?.message}</Alert>
      </Snackbar>

      <Dialog
        open={Boolean(openState.confirm)}
        onClose={handleConfirm(false)}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmationMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm(false)} color="inherit">Cancel</Button>
          <Button onClick={handleConfirm(true)} variant="contained" color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>
    </notificationsContext.Provider>
  );
};

export default NotificationsProvider;
