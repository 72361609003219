
import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import MuiTabs, { tabsClasses } from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import MuiAvatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import bgGradient from '../@lib/images/bg-gradient-2.png';
import PersonalDetailsForm from './PersonalDetailsForm';
import PasswordUpdateForm from './PasswordUpdateForm';

import { useState } from 'react';
import { styled } from '@mui/material/styles';

const Banner = styled(Box)({
  height: 240,
  backgroundImage: `url(${bgGradient})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover'
});

const Avatar = styled(MuiAvatar)({
  border: `4px solid #ffffff`
});

const Tabs = styled(MuiTabs)({
  borderRadius: 0,
  border: 0,
  borderBottom: `1px solid #E4E7EC`,
  [`.${tabsClasses.indicator}`]: {
    display: 'block'
  }
});

const Tab = styled(MuiTab)({
  borderRight: 'none',

});

const AccountSettings:React.FC = () => {
  const [currTabIndex, setCurrTabIndex] = useState(0);

  return (
    <Box mb={24}>
      <Banner />
      <Paper component={Container} sx={{ pb: 3, mb: 3 }}>
        <Stack direction="row" gap={3} alignItems="center">
          <Avatar sx={{ width: 180, height: 180, mt: -5 }} />
          <div>
            <Typography variant="h3">Name</Typography>
            <Typography>olivia@untitledui.com</Typography>
          </div>
        </Stack>
      </Paper>

      <Container>
        <Paper component={Container} sx={{ pb: 6 }}>
          <Tabs value={currTabIndex} onChange={(ev, i) => setCurrTabIndex(i)}>
            <Tab label="My details"/>
            <Tab label="Password" />
          </Tabs>

          {currTabIndex === 0 && (
            <>
              <Box py={6}>
                <PersonalDetailsForm />
              </Box>
              <Divider sx={{ my: 2 }} />
              <Stack direction="row" justifyContent="flex-end" gap={2}>
                <Button
                  size="large"
                  variant="contained"
                  color="inherit"
                  sx={{ px: 2 }}
                >Cancel</Button>
                <Button
                  size="large"
                  variant="contained"
                  sx={{ px: 2 }}
                >Update details</Button>
              </Stack>
            </>
          )}

          {currTabIndex === 1 && (
            <>
              <Box pb={6}>
                <PasswordUpdateForm />
              </Box>
              <Divider sx={{ my: 2 }} />
              <Stack direction="row" justifyContent="flex-end" gap={2}>
                <Button
                  size="large"
                  variant="contained"
                  color="inherit"
                  sx={{ px: 2 }}
                >Cancel</Button>
                <Button
                  size="large"
                  variant="contained"
                  sx={{ px: 2 }}
                >Update password</Button>
              </Stack>
            </>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default AccountSettings;
