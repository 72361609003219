
import { FC } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Divider from '@mui/material/Divider';

const PasswordUpdateForm:FC = () => {
  return (
    <form>
      <Box py={4}>
        <Typography fontSize={20} fontWeight={500}>Password</Typography>
        <Typography fontWeight={300}>Please enter your current password to change your password.</Typography>
      </Box>
      
      <Stack gap={3}>
        <Divider />

        <Stack direction={{md: 'row'}} gap={1}>
          <Typography sx={{ flexBasis: {md: '40%', lg: '30%', xl: '25%'} }}>Current password</Typography>
          <FormControl sx={{ flexBasis: {md: '60%', lg: '40%'} }}>
            <OutlinedInput type="password" />
          </FormControl>
        </Stack>

        <Divider />

        <Stack direction={{md: 'row'}} gap={1}>
          <Typography sx={{ flexBasis: {md: '40%', lg: '30%', xl: '25%'} }}>New password</Typography>
          <FormControl sx={{ flexBasis: {md: '60%', lg: '40%'} }}>
            <OutlinedInput type="password" />
            <FormHelperText sx={{ mt: 1 }}>Your new password must be more than 8 characters.</FormHelperText>
          </FormControl>
        </Stack>

        <Divider />

        <Stack direction={{md: 'row'}} gap={1}>
          <Typography sx={{ flexBasis: {md: '40%', lg: '30%', xl: '25%'} }}>Confirm new password</Typography>
          <FormControl sx={{ flexBasis: {md: '60%', lg: '40%'} }}>
            <OutlinedInput type="password" />
          </FormControl>
        </Stack>
      </Stack>
    </form>
  );
};

export default PasswordUpdateForm;
