
import type { ForwardRefRenderFunction } from 'react';
import type { OptionType, IDType } from '../../types';
import Select, { SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

import { forwardRef } from 'react';
import { useQuery, gql } from '@apollo/client';

export type ActionsDropdownProps = SelectProps & {};

const actionsQueryDocument = gql`
query Actions {
  actions {
    label: description
    value: id
  }
}
`;

const ActionsDropdown:ForwardRefRenderFunction<HTMLElement, ActionsDropdownProps> = (props, ref) => {
  const {
    children,
    ...selectProps
  } = props;

  const { data, loading } = useQuery<{actions: OptionType<IDType>[]}>(actionsQueryDocument);

  return (
    <Select
      ref={ref}
      displayEmpty={!loading}
      startAdornment={loading && (
        <InputAdornment position="start">
          <CircularProgress size={20} />
        </InputAdornment>
      )}
      {...selectProps}
    >
      <MenuItem value="" disabled>Select action</MenuItem>
      {data?.actions.map((op, i) => (
        <MenuItem key={`${op.value}-${i}`} value={op.value}>{op.label}</MenuItem>
      ))}
    </Select>
  );
};

export default forwardRef(ActionsDropdown);
