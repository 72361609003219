
import React from 'react';
import MuiOutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import SvgIcon from '@mui/material/SvgIcon';
import { MdClear } from "react-icons/md";
import { ReactComponent as SearchIcon } from '../../../icons/search-icon.svg';

import { useState } from 'react';
import { styled } from '@mui/material/styles';

export type SearchBarProps = Omit<OutlinedInputProps, 'startAdornment'> & {
  collapsed?: boolean;
};

const OutlinedInput = styled(MuiOutlinedInput)(({ theme }) => ({
  fontSize: 14,
  borderRadius: 100,
  backgroundColor: theme.palette.background.default,
  transition: 'background-color 300ms',
  '&:hover': {
    backgroundColor: theme.palette.background.paper
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
    transition: 'border-color 300ms',
  }
}));

const SearchBar:React.FC<SearchBarProps> = (props) => {
  const {
    collapsed,
    ...otherProps
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement|null>(null);

  const handleClick = (ev:React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(ev.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const inputEle = (
    <OutlinedInput
      {...otherProps}
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon component={SearchIcon} inheritViewBox color="primary" />
        </InputAdornment>
      }
    />
  );

  if (collapsed)
    return (
      <>
        <IconButton onClick={handleClick}>
          {anchorEl ? (
            <MdClear />
          ):(
            <SvgIcon component={SearchIcon} inheritViewBox color="primary" />
          )}
        </IconButton>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          disableScrollLock
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          elevation={4}
          slotProps={{paper: {sx: { p: 1, borderRadius: 100 }}}}
        >{inputEle}</Popover>
      </>
    );

  return inputEle;
};

export default SearchBar;
