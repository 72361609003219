import React, { useEffect, useState } from "react";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from "@mui/material";
import { NavLink } from "react-router-dom"; // Assuming you're using react-router
import { ReactComponent as PoliciesIcon } from "../../../icons/policies-icon.svg";
import { ReactComponent as PracticesIcon } from "../../../icons/badge-icon.svg";
import { ReactComponent as PeopleIcon } from "../../../icons/people-icon.svg";
import { ConditionalRenderer } from "../../conditionalRenderer";
import { MySpaceIcon } from "../../../icons";

const mainMenuItems = [
  {
    path: "/submission/policies",
    label: "Policies",
    icon: <SvgIcon component={PoliciesIcon} inheritViewBox />,
  },
  {
    path: "/submission/practices",
    label: "Practices",
    icon: <SvgIcon component={PracticesIcon} inheritViewBox />,
  },
  {
    path: "/submission/people",
    label: "People",
    icon: <SvgIcon component={PeopleIcon} inheritViewBox />,
  },
];

const MySpaceMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // const [open, setOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    // setOpen((prevOpen) => !prevOpen);
  };


  

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon sx={{ minWidth: 37 }}>
          <MySpaceIcon />
        </ListItemIcon>
        <ListItemText
          primary="My Submissions"
          primaryTypographyProps={{
            fontWeight: 500,
          }}
        />
      </ListItemButton>
      <ConditionalRenderer condition={open}>
        {mainMenuItems.map((item, i) => (
          <ListItemButton
            key={`mySubmission-${i}`}
            component={NavLink}
            to={item.path}
            sx={{
              borderLeft: 4,
              borderColor: "transparent",
              marginLeft:"30px",
              "&.active": {
                borderColor: "#F6A000",
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{
                fontWeight: 500,
              }}
            />
          </ListItemButton>
        ))}
      </ConditionalRenderer>
    </>
  );
};

export default MySpaceMenu;
