import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import "./styles.css";

type ListItem = { value: string };

type SelectInputT = {
  label: string;
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  listItems: ListItem[];
  styling?: any;
};

function SelectInput({
  label,
  value,
  onChange,
  listItems,
  styling,
}: SelectInputT) {
  return (
    <FormControl fullWidth className="selectInput">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        sx={styling}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={label}
        onChange={onChange}
      >
        {listItems?.map((elem) => (
          <MenuItem value={elem?.value}>{elem?.value}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export { SelectInput };
