import { useMemo } from "react";
import { useQuery, gql } from "@apollo/client";
import type { Person } from "../../types";

const peopleQueryDocument = gql`
  query People($limit: Int) {
    people(limit: $limit) {
      id
      firstname
      lastname
      title
    }
  }
`;

const privateQueryDocument = gql`
  query People {
    people {
      id
      firstname
      lastname
      title
      status
    }
  }
`;

type PersonInfo = Pick<Person, "id" | "firstname" | "lastname" | "title">;

const usePeoplesCall = ({
  isPrivate = false,
  limit = {},
  user = { accessToken: "", client: "", id: "" },
}: any) => {
  const queryDocument = isPrivate ? privateQueryDocument : peopleQueryDocument;

  const paramsObject: any = isPrivate
    ? {
        fetchPolicy: "network-only",
        skip: !user?.accessToken || !user?.client || !user?.id,
        context: {
          headers: {
            "access-token": user?.accessToken,
            client: user?.client,
            uid: user?.id,
          },
        },
      }
    : {
        fetchPolicy: "network-only",
        variables: limit,
      };

  const { data, loading, refetch } = useQuery<any>(queryDocument, paramsObject);

  return {
    data,
    loading,
    refetch,
  };
};

export { usePeoplesCall };
