import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CTAContainer from "../../../@lib/components/@common/CTAContainer";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import Fade from "@mui/material/Fade";
import { Policy, IDType } from "../../../@lib/types";
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import { PolicyStatus } from "../../../@lib/hooks/usePoliciesCollection";
import { useNotify } from "../../../@lib/components/@common/NotificationsProvider";
import { useAuthSession } from "../../../@lib/components/AuthSessionProvider";
import {
  Attachment,
  Card,
  CardSkeleton,
  Filters,
  Header,
  LatestReport,
  PublicAndPolicyFilters,
} from "../../../@lib/components";
import usePrivatePoliciesCall from "../../../@lib/hooks/usePrivatePoliciesCall";
import { usePoliciesDocumentsCall } from "../../../@lib/hooks";

const policyDeleteMutationDocument = gql`
  mutation policyDeleteMutation($policy: PolicyDeleteInput!) {
    policyDelete(input: $policy) {
      policy {
        id
      }
    }
  }
`;

// interface PrivatePoliciesProps {
//   dist: {
//     approved: PolicyData[];
//     rejected: PolicyData[];
//     pending: PolicyData[];
//     all: PolicyData[];
//   };
//   setCurrTab: React.Dispatch<React.SetStateAction<PolicyStatus | "all">>;
//   currTab: PolicyStatus | "all";
//   loading: boolean;
//   policies: PolicyData[];
//   refetch: (variables?: Partial<any>) => Promise<any>;
// }

const PrivatePolicies: React.FC<any> = () => {
  const { policyDocuments } = usePoliciesDocumentsCall({}) as any;
  const { user } = useAuthSession();
  const [currTab, setCurrTab] = useState<PolicyStatus | "all">("all");
  const {
    policies = [],
    dist,
    loading,
    refetch,
  } = usePrivatePoliciesCall({
    user,
    policyStatus: currTab,
    variables: { limit: 8 },
  });
  const [attachmentDetails, setAttachmentDetails] = useState({ name: "" });
  const notify = useNotify();
  const navigate = useNavigate();
  const [deletePolicy, { loading: deletingPolicy }] = useMutation(
    policyDeleteMutationDocument
  );

  /**
   * handleDeletePolicy()
   */
  const handleDeletePolicy = useCallback(
    async (policy: Pick<Policy, "id" | "title">) => {
      if (
        await notify.confirm(
          `Deleting policy "${policy.title}". This cannot be undone.`
        )
      ) {
        try {
          await deletePolicy({ variables: { policy: { id: policy.id } } });
          refetch();
        } catch (err) {
          notify.error(err as Error);
        }
      }
    },
    [deletePolicy, refetch, notify]
  );

  /**
   * handleEditPolicy()
   */
  const handleEditPolicy = useCallback(
    (policyId: IDType) => navigate(`/policies/${policyId}/edit`),
    [navigate]
  );

  const privateTab = [
    {
      value: "all",
      count: dist.all.length,
      title: "All",
    },
    {
      value: "approved",
      count: dist.approved.length,
      title: "Approved",
    },
    {
      value: "pending",
      count: dist.pending.length,
      title: "Pending",
    },
    {
      value: "rejected",
      count: dist.rejected.length,
      title: "Rejected",
    },
  ];

  return (
    <Box pb={4}>
      <Header title="Policies" subtitle="Lorem ipsum dolor sit amet." />
      <Filters />

      <Container sx={{ my: 3 }}>
        <PublicAndPolicyFilters
          dist={dist}
          currTab={currTab}
          setCurrTab={setCurrTab}
          listItems={privateTab}
          heading="List of policies data"
        />
        <Grid container spacing={2}>
          {policies?.map((p: any, i: any) => (
            <Grid key={`${i}`} item xs={12} md={6} lg={3}>
              <Slide in timeout={180 * (i + 1)} direction="right">
                <Card
                  {...p}
                  path={`/policies/${p?.id}`}
                  editPath={`/policies/${p?.id}/edit`}
                  disabled={deletingPolicy}
                  onDelete={() => handleDeletePolicy(p)}
                  onEdit={handleEditPolicy}
                  sx={{ height: "100%" }}
                  isPrivate={true}
                />
              </Slide>
            </Grid>
          ))}

          {loading &&
            policies.length === 0 &&
            Array(4)
              .fill(null)
              .map((_, i) => (
                <Fade key={`${i}`} in timeout={180 * (i + 1)}>
                  <Grid item xs={12} md={6} lg={3}>
                    {CardSkeleton}
                  </Grid>
                </Fade>
              ))}
        </Grid>
      </Container>

      <Container sx={{ my: 3 }}>
        <Fade in timeout={800}>
          <CTAContainer sx={{ textAlign: "center" }}>
            <Typography variant="subtitle1" mb={2}>
              Add new policies to the platform
            </Typography>
            <Button
              component={RouterLink}
              to="new"
              sx={{
                px: 6,
                backgroundColor: "#ffffff",
                color: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                },
              }}
            >
              CREATE
            </Button>
          </CTAContainer>
        </Fade>
      </Container>

      <LatestReport items={policyDocuments} path="/policies/viewAllReports" />

      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3, mb: 2 }}>
          <Typography variant="h4">Data Import</Typography>
        </Paper>

        <Attachment
          allowedFormats={["pdf"]}
          text="PDF file (max. 50mb)"
          setAttachmentDetails={setAttachmentDetails}
          attachmentDetails={attachmentDetails}
        />
        <Box textAlign="right">
          <Button variant="contained" sx={{ px: 6 }} disabled>
            Import
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default PrivatePolicies;
