
import type { ForwardRefRenderFunction } from 'react';
import Select, { SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

import { forwardRef } from 'react';
import useThemeOptions from '../../hooks/useThemeOptions';

export type ThemesDropdownProps = SelectProps & {};

const ThemesDropdown:ForwardRefRenderFunction<HTMLElement, ThemesDropdownProps> = (props, ref) => {
  const {
    children,
    ...selectProps
  } = props;

  const [options, loading] = useThemeOptions();

  return (
    <Select
      ref={ref}
      displayEmpty={!loading}
      startAdornment={loading && (
        <InputAdornment position="start">
          <CircularProgress size={20} />
        </InputAdornment>
      )}
      {...selectProps}
    >
      <MenuItem value="" disabled>Select theme</MenuItem>
      {options.map((op, i) => (
        <MenuItem key={`${op.value}-${i}`} value={op.value}>{op.label}</MenuItem>
      ))}
    </Select>
  );
};

export default forwardRef(ThemesDropdown);
