
import type { FC, ReactNode } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import Link from '@mui/material/Link';
import { MdExpandMore } from "react-icons/md";

import { useCallback, useRef } from 'react';
import { styled } from '@mui/material/styles';

export type YearsRange = {
  startYear: number;
  endYear: number;
};

export type TimeInputAccordionProps = Omit<AccordionProps, 'children'|'title'|'onChange'> & {
  title?: ReactNode;
  value: YearsRange;
  onChange: (val:YearsRange)=>void;
  loading?: boolean;
  disabled?: boolean;
};

const Accordion = styled(MuiAccordion)({
  '&.Mui-disabled': {
    backgroundColor: 'white'
  }
});

const AccordionSummary = styled(MuiAccordionSummary)(({theme}) => ({
  paddingLeft: theme.spacing(3),
  '&.Mui-expanded': {
    backgroundColor: '#F6F5F2',
    '&:before': {
      content: `""`,
      display: 'inline-block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      borderLeft: `4px solid ${theme.palette.primary.main}`
    }
  }
}));

const TimeInputAccordion:FC<TimeInputAccordionProps> = (props) => {
  const {
    title,
    value,
    onChange,
    loading,
    disabled,
    ...accordionProps
  } = props;

  const startYearInput = useRef<HTMLInputElement>(null);
  const endYearInput = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(() => {
    onChange({
      startYear: Number(startYearInput.current?.value) || 0,
      endYear: Number(endYearInput.current?.value) || 0,
    });
  }, [onChange]);

  const setRange = useCallback((numYears:number) => () => {
    const today = new Date();
    onChange({
      startYear: today.getFullYear() - numYears,
      endYear: today.getFullYear()
    });
  }, [onChange]);

  return (
    <Accordion {...accordionProps} disabled={disabled}>
      <AccordionSummary
        expandIcon={<MdExpandMore size={24} />}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>
        <Box p={1}>
          <Typography variant="body2">
            View recent years:
            <Link sx={{cursor: 'pointer'}} px={1} ml={1} color="secondary" underline="hover" onClick={setRange(5)}>5</Link>
            <Link sx={{cursor: 'pointer'}} px={1} color="secondary" underline="hover" onClick={setRange(10)}>10</Link>
            <Link sx={{cursor: 'pointer'}} px={1} color="secondary" underline="hover" onClick={setRange(15)}>15</Link>
            <Link sx={{cursor: 'pointer'}} px={1} color="secondary" underline="hover" onClick={setRange(20)}>20</Link>
          </Typography>
        </Box>
        <Box p={1}>
          <Typography mb={1.5}>Add year</Typography>
          <Stack direction="row" alignItems="center" gap={2}>
            <OutlinedInput
              inputRef={startYearInput}
              type="number"
              placeholder="Year"
              value={value.startYear}
              onChange={handleChange}
            />
            <Typography>to</Typography>
            <OutlinedInput
              inputRef={endYearInput}
              type="number"
              placeholder="Year"
              value={value.endYear}
              onChange={handleChange}
            />
          </Stack>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default TimeInputAccordion;
