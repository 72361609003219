import React from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import Button from "@mui/material/Button";
import CTAContainer from "../../@lib/components/@common/CTAContainer";

import { useState } from "react";
import { useAuthSession } from "../../@lib/components/AuthSessionProvider";
import { PolicyStatus } from "../../@lib/hooks/usePoliciesCollection";
import {
  CardAll,
  ConditionalRenderer,
  Header,
  PublicAndPolicyFilters,
} from "../../@lib/components";
import { usePublicPoliciesCall } from "../../@lib/hooks";

const PoliciesList: React.FC = () => {
  const { user } = useAuthSession();
  const isLoggedIn = !!user;
  const [pageNumber, setPageNumber] = useState(1);
  const [currTab, setCurrTab] = useState<PolicyStatus | "all" | any>("all");
  const { policies, dist, loading, refetch, metadata }: any =
    usePublicPoliciesCall({ limit: 8, page: pageNumber }, currTab);

  function onClickHandler() {
    if (pageNumber === metadata?.totalPages) return;
    setPageNumber(pageNumber + 1);
  }

  const publicTabs = [
    {
      value: "all",
      count: dist?.all?.length,
      title: "All",
    },
    {
      value: "lois",
      count: dist?.lois?.length,
      title: "Lois",
    },
    {
      value: "décrets",
      count: dist?.decrets?.length,
      title: "Décrets",
    },
    {
      value: "lettresSectorielles",
      count: dist?.lettresSectorielles?.length,
      title: "Lettres sectorielles",
    },
    {
      value: "other",
      count: dist?.other?.length,
      title: "Other",
    },
  ];

  const hasMore = pageNumber === metadata?.totalPages;

  return (
    <div>
      <Header
        title="Policies"
        subtitle="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
      />

      <Container sx={{ my: 3 }}>
        <PublicAndPolicyFilters
          currTab={currTab}
          setCurrTab={setCurrTab}
          listItems={publicTabs}
          heading="List of policies data"
          isAllShow={true}
        />

        <Stack gap={2}>
          {policies.map((p: any, i: any) => (
            <CardAll p={p} loading={loading} path={`/policies/${p.id}`} />
          ))}
        </Stack>
      </Container>

      <ConditionalRenderer condition={!hasMore}>
        <Container sx={{ my: 3 }}>
          <Paper sx={{ py: 2, display: "flex", justifyContent: "center" }}>
            <Button onClick={onClickHandler}>Show more</Button>
          </Paper>
        </Container>
      </ConditionalRenderer>
    </div>
  );
};

export default PoliciesList;
