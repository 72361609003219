
import type { ForwardRefRenderFunction } from 'react';
import type { OptionType, IDType } from '../../types';
import Select, { SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

import { forwardRef } from 'react';
import { useQuery, gql } from '@apollo/client';

export type RolesDropdownProps = SelectProps & {};

const rolesQueryDocument = gql`
query Roles {
  roles {
    label: name
    value: id
  }
}
`;

const RolesDropdown:ForwardRefRenderFunction<HTMLElement, RolesDropdownProps> = (props, ref) => {
  const {
    children,
    ...selectProps
  } = props;

  const { data, loading } = useQuery<{roles: OptionType<IDType>[]}>(rolesQueryDocument);

  return (
    <Select
      ref={ref}
      displayEmpty={!loading}
      startAdornment={loading && (
        <InputAdornment position="start">
          <CircularProgress size={20} />
        </InputAdornment>
      )}
      {...selectProps}
    >
      <MenuItem value="" disabled>Select role</MenuItem>
      {data?.roles.map((op, i) => (
        <MenuItem key={`${op.value}-${i}`} value={op.value}>{op.label}</MenuItem>
      ))}
    </Select>
  );
};

export default forwardRef(RolesDropdown);
