import { useState } from "react";
import { Box, Container, Fade, Grid, Slide } from "@mui/material";
import {
  Card,
  CardSkeleton,
  Filters,
  Header,
  LatestReport,
  PublicAndPolicyFilters,
} from "../../../@lib/components";
import {
  PolicyStatus,
  usePoliciesDocumentsCall,
  usePublicPoliciesCall,
} from "../../../@lib/hooks";

function PublicPolicies() {
  const [filters, setFilters] = useState({
    country: "",
    period: "",
    theme: "",
  });
  const [currTab, setCurrTab] = useState<PolicyStatus | "all">("all");


  const { policyDocuments } = usePoliciesDocumentsCall({}) as any;
  const {
    policies = [],
    dist,
    loading,
    refetch,
  } = usePublicPoliciesCall({ limit: 8 }, currTab);



  const publicTabs = [
    {
      value: "all",
      count: dist?.all?.length,
      title: "All",
    },
    {
      value: "lois",
      count: dist?.lois?.length,
      title: "Lois",
    },
    {
      value: "décrets",
      count: dist?.decrets?.length,
      title: "Décrets",
    },
    {
      value: "lettresSectorielles",
      count: dist?.lettresSectorielles?.length,
      title: "Lettres sectorielles",
    },
    {
      value: "other",
      count: dist?.other?.length,
      title: "Other",
    },
  ];

  return (
    <Box pb={4}>
      <Header title="Policies" subtitle="Lorem ipsum dolor sit amet." />
      <Filters setFilters={setFilters} filters={filters} />
      <Container sx={{ my: 3 }}>
        <PublicAndPolicyFilters
          currTab={currTab}
          setCurrTab={setCurrTab}
          listItems={publicTabs}
          heading="List of policies data"
        />
        <Grid container spacing={2}>
          {policies?.map((p: any, i: any) => (
            <Grid key={`${i}`} item xs={12} md={6} lg={3}>
              <Slide in timeout={180 * (i + 1)} direction="right">
                <Card {...p} path={`${p?.id}`} sx={{ height: "100%" }} />
              </Slide>
            </Grid>
          ))}
          {loading &&
            policies.length === 0 &&
            Array(4)
              .fill(null)
              .map((_, i) => (
                <Fade key={`${i}`} in timeout={180 * (i + 1)}>
                  <Grid item xs={12} md={6} lg={3}>
                    {CardSkeleton}
                  </Grid>
                </Fade>
              ))}
        </Grid>
      </Container>
      <LatestReport items={policyDocuments} path="/policies/viewAllReports" />
    </Box>
  );
}

export default PublicPolicies;
