
import type { FC } from 'react';
import Select, { SelectProps } from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import { FaChevronDown, FaLanguage } from "react-icons/fa6";

export type I18nSelectProps = SelectProps & {
  options: {label: string; value: string}[];
};

const I18nSelect:FC<I18nSelectProps> = (props) => {
  const {
    options,
    children,
    ...selectProps
  } = props;

  return (
    <Select
      size="small"
      variant="standard"
      disableUnderline
      IconComponent={FaChevronDown}
      startAdornment={(
        <InputAdornment position="start">
          <FaLanguage size={20} color="#EF8400" />
        </InputAdornment>
      )}
      {...selectProps}
    >
      {options.map((op, i) => (
        <MenuItem key={`${i}`} value={op.value}>{op.label}</MenuItem>
      ))}
    </Select>
  );
};

export default I18nSelect;
