function AttachmentUploadIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
      focusable="false"
      aria-hidden="true"
    >
      <g clip-path="url(#clip0_70_708)">
        <path
          d="M13.3335 13.3333L10.0002 10M10.0002 10L6.66688 13.3333M10.0002 10V17.5M16.9919 15.325C17.8047 14.8819 18.4467 14.1807 18.8168 13.3322C19.1868 12.4836 19.2637 11.536 19.0354 10.6389C18.807 9.74179 18.2865 8.94626 17.5558 8.37787C16.8251 7.80948 15.9259 7.50061 15.0002 7.5H13.9502C13.698 6.52436 13.2278 5.61861 12.5752 4.85082C11.9225 4.08304 11.1042 3.47321 10.182 3.06717C9.25967 2.66113 8.25734 2.46946 7.25031 2.50657C6.24328 2.54367 5.25777 2.80858 4.36786 3.28138C3.47795 3.75419 2.7068 4.42258 2.1124 5.23631C1.51799 6.05005 1.11579 6.98794 0.936028 7.97949C0.756269 8.97104 0.803632 9.99044 1.07456 10.961C1.34548 11.9317 1.83291 12.8282 2.50021 13.5833"
          stroke="#F9B500"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_70_708">
          <rect width="20" height="20"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export { AttachmentUploadIcon };
