
import { createTheme, lighten } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#F9B500',
      light: lighten('#F9B500', 0.85),
      dark: '#EF8400'
    },
    secondary: {
      main: '#5E71B0'
    },
    warning: {
      main: '#FE9E58',
      light: lighten('#FE9E58', 0.85)
    },
    success: {
      main: '#19cb4b',
      light: lighten('#19cb4b', 0.85)
    },
    error: {
      main: '#FA6262',
      light: lighten('#FA6262', 0.85)
    },
    text: {
      primary: '#494E54',
      secondary: '#989C9F'
    },
    background: {
      default: '#F9F9F9'
    }
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    h1: { fontWeight: 400, fontSize: 56 },
    h2: { fontWeight: 700, fontSize: 48 },
    h3: { fontWeight: 600, fontSize: 30 },
    h4: { fontWeight: 700, fontSize: 18 },
    h5: { fontWeight: 600, fontSize: 16 },
    h6: { fontWeight: 700, fontSize: 14 },
    caption: { fontWeight: 300 },
    subtitle1: { fontWeight: 600, fontSize: 18 },
    subtitle2: { fontWeight: 600, fontSize: 14 },
    button: { fontSize: 16 }
  },
  shape: {
    borderRadius: 8
  },
  zIndex: {
    drawer: 1100,
    appBar: 1200
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: false
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 4
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          border: `1px solid #E4E7EC`,
          borderRadius: 100
        },
        indicator: {
          display: 'none'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'initial',
          fontSize: 14,
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          borderRight: '1px solid #E4E7EC',
          '&:last-child': {
            borderRight: 'none'
          }
        })
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: '#E4E7EC'
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: lighten(theme.palette.text.primary, 0.85),
          color: theme.palette.text.secondary,
          boxShadow: theme.shadows[4]
        })
      }
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave'
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: 24
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          textTransform: 'initial'
        },
        sizeMedium: ({ theme }) => ({
          paddingTop: theme.spacing(0.75),
          paddingBottom: theme.spacing(0.75),
          fontSize: 14
        }),
        sizeLarge: ({ theme }) => ({
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          fontSize: 16
        }),
        containedPrimary: ({ theme }) => ({
          color: '#ffffff',
          backgroundColor: theme.palette.primary.dark
        })
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: 14,
          lineHeight: 1.2,
          fontWeight: 500,
          color: theme.palette.text.primary
        })
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: 14,
          lineHeight: 1.2,
          fontWeight: 500,
          color: theme.palette.text.primary,
          marginBottom: theme.spacing(1)
        })
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 14,
          fontWeight: 500
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: ({ theme }) => ({
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(1.5)
        }),
        notchedOutline: {
          borderColor: '#E4E7EC'
        }
      }
    }
  }
});

lightTheme.shadows[3] = '2px 4px 5px rgba(118, 118, 118, 0.25)';
lightTheme.shadows[4] = '0 5px 4px rgba(219, 219, 219, 0.25)';
lightTheme.shadows[5] = '4px 5px 4px rgba(219, 219, 219, 0.25)';

export default lightTheme;
