
import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

export type PeopleInfoFormProps = {
  disabled?: boolean;
};

const PeopleInfoForm:React.FC<PeopleInfoFormProps> = () => {
  return (
    <Stack gap={2}>
      <Box component={Paper} variant="outlined" bgcolor="grey.100" sx={{ minHeight: 300, p: 2 }}>
        <Typography variant="caption">No people already selected</Typography>
      </Box>

      <Stack direction={{ md: 'row' }} gap={2} alignItems={{ md: 'flex-end' }}>
        <FormControl sx={{ flexBasis: { xs: '100%', md: '60%', lg: '40%' } }} disabled>
          <FormLabel>Select people</FormLabel>
          <Select
            placeholder="Please select practices"
          />
        </FormControl>

        <Button variant="contained" disabled>Add new people</Button>
      </Stack>
    </Stack>
  );
};

export default PeopleInfoForm;
