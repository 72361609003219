
import React from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import InstitutionsDropdown from '../InstitutionsDropdown';
import RolesDropdown from '../RolesDropdown';
import { MdExpandMore } from "react-icons/md";
import { Controller } from 'react-hook-form';
import CareerDetailTabs from './CareerDetailTabs';
import CareerActionsForm from './CareerActionsForm';
import CareerPositionsForm from './CareerPositionsForm';

import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

export type CareerFormProps = {
  name: string;
  disabled?: boolean;
};

const CareerForm:React.FC<CareerFormProps> = (props) => {
  const {
    name,
    disabled
  } = props;

  const {formState: { errors }} = useFormContext();

  const {fields, append} = useFieldArray({
    name
  });

  const handleAddCareer = useCallback(() => {
    append({
      institutionId: '',
      roleId: '',
      startDate: '',
      endDate: '',
      careerActions: [{
        actionId: '',
        themeId: '',
        countryId: '',
        actionDate: ''
      }],
      careerPositions: [{
        positionId: '',
        themeId: '',
        countryId: '',
        positionDate: ''
      }]
    });
  }, [append]);

  return (
    <>
      <Paper component={Container} sx={{ py: 2, mb: 2 }}>
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Career</Typography>
          <Button
            variant="contained"
            disabled={disabled}
            onClick={handleAddCareer}
            sx={{ px: 6 }}
          >Add more</Button>
        </Stack>
      </Paper>

      {fields.map((data, i) => (
        <Accordion key={data.id} defaultExpanded>
          <AccordionSummary expandIcon={<MdExpandMore fontSize={28} />}></AccordionSummary>
          <AccordionDetails>
            <Stack direction={{ md: 'row' }} sx={{ mb: 3 }} spacing={3}>
              <Stack direction={{ sm: 'row' }} spacing={2} flex={1}>
                <Controller
                  name={`${name}.${i}.institutionId`}
                  render={({field, fieldState: { error }}) => (
                    <FormControl required disabled={disabled} error={!!error} sx={{ flex: 1 }}>
                      <FormLabel>Institution</FormLabel>
                      <InstitutionsDropdown {...field} />
                      <FormHelperText>{error?.message}</FormHelperText>
                    </FormControl>
                  )}
                />

                <Controller
                  name={`${name}.${i}.roleId`}
                  render={({field, fieldState: { error }}) => (
                    <FormControl required disabled={disabled} error={Boolean(error)} sx={{ flex: 1 }}>
                      <FormLabel>Role</FormLabel>
                      <RolesDropdown {...field} />
                      <FormHelperText>{error?.message}</FormHelperText>
                    </FormControl>
                  )}
                />
                
              </Stack>
              <Stack direction={{ sm: 'row' }} spacing={2} flex={1}>
                <Controller
                  name={`${name}.${i}.startDate`}
                  render={({field, fieldState: { error }}) => (
                    <FormControl required disabled={disabled} error={!!error} sx={{ flex: 1 }}>
                      <FormLabel>Period</FormLabel>
                      <OutlinedInput
                        {...field}
                        placeholder="Start date"
                      />
                      <FormHelperText>{error?.message}</FormHelperText>
                    </FormControl>
                  )}
                />
                
                <Controller
                  name={`${name}.${i}.endDate`}
                  render={({field, fieldState: { error }}) => (
                    <FormControl required disabled={disabled} error={!!error} sx={{ flex: 1 }}>
                      <FormLabel>&nbsp;</FormLabel>
                      <OutlinedInput
                        {...field}
                        placeholder="End date"
                      />
                      <FormHelperText>{error?.message}</FormHelperText>
                    </FormControl>
                  )}
                />
                
              </Stack>
            </Stack>

            <CareerDetailTabs
              errors={{
                // @ts-ignore
                action: Boolean(errors?.careers?.at(i)?.careerActions),
                // @ts-ignore
                position: Boolean(errors?.careers?.at(i)?.careerPositions)
              }}
              sx={{ width: 'fit-content', my: 4 }}
              render={(currTabIndex) => currTabIndex === 0 ? (
                <CareerActionsForm
                  name={`${name}.${i}.careerActions`}
                  disabled={disabled}
                />
              ):(
                <CareerPositionsForm
                  name={`${name}.${i}.careerPositions`}
                  disabled={disabled}
                />
              )}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default CareerForm;
