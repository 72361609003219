
import type { OptionType, IDType } from '../types';
import {useQuery, gql} from '@apollo/client';

const themesQueryDocument = gql`
query Themes {
  themes {
    label: title
    value: id
  }
}
`;

const useThemeOptions = ():[OptionType[], boolean] => {
  const { data, loading } = useQuery<{themes: OptionType<IDType>[]}>(themesQueryDocument);
  return [data?.themes || [], loading]
};

export default useThemeOptions;
