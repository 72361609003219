
import type { FC, ReactNode } from 'react';
import type { OptionType } from '../@lib/types';

import Box from '@mui/material/Box';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Alert from '@mui/material/Alert';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormGroup from '@mui/material/FormGroup';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import SvgIcon from '@mui/material/SvgIcon';
import { MdExpandMore } from "react-icons/md";
import {ReactComponent as SearchIcon} from '../@lib/icons/search-icon.svg';

import { useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';

export type OptionsAccordionProps<T = any> = Omit<AccordionProps, 'children'|'title'|'onChange'> & {
  options: OptionType<T>[];
  title?: ReactNode;
  value?: T;
  values?: Set<T>|null; // For multiple selection
  multiple?: boolean;
  onChange?: (val:T)=>void;
  onMultipleSelect?: (values:Set<T>)=>void; // For multiple selection
  loading?: boolean;
  disabled?: boolean;
};

const Accordion = styled(MuiAccordion)({
  '&.Mui-disabled': {
    backgroundColor: 'white'
  }
});

const AccordionSummary = styled(MuiAccordionSummary)(({theme}) => ({
  paddingLeft: theme.spacing(3),
  '&.Mui-expanded': {
    backgroundColor: '#F6F5F2',
    '&:before': {
      content: `""`,
      display: 'inline-block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      borderLeft: `4px solid ${theme.palette.primary.main}`
    }
  }
}));

const OptionsAccordion:FC<OptionsAccordionProps> = (props) => {
  const {
    options,
    title,
    value,
    values,
    multiple,
    onChange,
    onMultipleSelect,
    loading,
    disabled,
    ...accordionProps
  } = props;

  const [searchVal, setSearchVal] = useState('');

  const filteredOptions = useMemo(() => {
    if (!searchVal.trim())
      return options;

    return options.filter((op) => {
      return `${op.label} ${op.value}`.toLowerCase().includes(searchVal.toLowerCase()) || op.value === value
    });
  }, [searchVal, value, options]);

  return (
    <Accordion {...accordionProps} disabled={disabled}>
      <AccordionSummary
        expandIcon={<MdExpandMore size={24} />}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>
        <Box my={1}>
          <OutlinedInput
            placeholder="Search keyword"
            value={searchVal}
            onChange={(ev) => setSearchVal(ev.target.value)}
            fullWidth
            disabled={disabled}
            startAdornment={(
              <InputAdornment position="start">
                <SvgIcon component={SearchIcon} inheritViewBox color="primary" />
              </InputAdornment>
            )}
            sx={{ borderRadius: 100, backgroundColor: '#F9F9F9' }}
          />
        </Box>

        {loading && (<CircularProgress size={24} />)}

        <Box sx={{ minHeight: 100, maxHeight: 350, overflow: 'auto' }}>
          {!multiple && (
            <RadioGroup
              value={value}
              onChange={(ev, val) => {onChange && onChange(val)}}
            >
              {filteredOptions.map((op, i) => (
                <FormControlLabel
                  key={`${i}-${op.value}`}
                  label={op.label}
                  value={op.value}
                  disabled={disabled}
                  control={<Radio/>}
                />
              ))}
            </RadioGroup>
          )}

          {multiple && (
            <FormGroup>
              {filteredOptions.map((op, i) => (
                <FormControlLabel
                  key={`${i}-${op.value}`}
                  label={op.label}
                  checked={values?.has(op.value) || false}
                  onChange={(ev, checked) => {
                    const newValues = new Set(values);
                    if (checked)
                      newValues.add(op.value);
                    else
                      newValues.delete(op.value);

                    onMultipleSelect && onMultipleSelect(newValues);
                  }}
                  disabled={disabled}
                  control={<Checkbox />}
                />
              ))}
            </FormGroup>
          )}

          {!loading && filteredOptions.length === 0 && (
            <Alert severity="info" >No options...</Alert>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default OptionsAccordion;
