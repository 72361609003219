import { Container, Grid, Link, Paper, Stack, Typography } from "@mui/material";
import { Card, LatestReport } from "../../@lib/components";
import { PoliciesCollectionData } from "../../@lib/hooks/usePoliciesCollection";
import { gql, useQuery } from "@apollo/client";
import { usePoliciesDocumentsCall } from "../../@lib/hooks";

const policiesQueryDocument = gql`
  query policiesQuery($limit: Int) {
    policies(limit: $limit) {
      id
      title
      description
      picture
      status
      policyCategory {
        name
      }
    }
  }
`;

function Policies() {
  const { policyDocuments } = usePoliciesDocumentsCall({
    limit: 5,
  }) as any;
  const { data, loading, refetch } = useQuery<PoliciesCollectionData>(
    policiesQueryDocument,
    { fetchPolicy: "network-only", variables: { limit: 4 } }
  );
  const { policies = [] } = data || {};

  return (
    <>
      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3, mb: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">Policies</Typography>
            <Link href="/policies/all" underline="none" color="textSecondary">
              View all
            </Link>
          </Stack>
        </Paper>

        <Grid container spacing={2}>
          {policies?.map((elem, i) => (
            <Grid key={`${i}`} item xs={12} md={6} lg={3}>
              <Card
                status={elem?.status}
                id={`policies/${elem?.id}`}
                title={elem?.title}
                description={elem?.description}
              />
            </Grid>
          ))}
        </Grid>
      </Container>

      <LatestReport items={policyDocuments} path="/policies/viewAllReports" />
    </>
  );
}

export { Policies };
