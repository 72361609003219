
import type { FC } from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

// import { useForm } from 'react-hook-form';

const PersonalDetailsForm:FC = () => {
  return (
    <form>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} xl={4}>
          <FormControl fullWidth>
            <FormLabel>Name</FormLabel>
            <OutlinedInput />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <FormControl fullWidth>
            <FormLabel>Last name</FormLabel>
            <OutlinedInput />
          </FormControl>
        </Grid>

        <Grid item xs={12} xl={4} />

        <Grid item xs={12} md={6} xl={4}>
          <FormControl fullWidth>
            <FormLabel>Birthdate</FormLabel>
            <OutlinedInput />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <FormControl fullWidth>
            <FormLabel>Email</FormLabel>
            <OutlinedInput />
          </FormControl>
        </Grid>

        <Grid item xs={12} xl={4} />

        <Grid item xs={12} md={6} xl={4}>
          <FormControl fullWidth>
            <FormLabel>Country</FormLabel>
            <Select />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <FormControl fullWidth>
            <FormLabel>Preferred language</FormLabel>
            <Select />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};

export default PersonalDetailsForm;
