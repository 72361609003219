import {
  Container,
  Link,
  Paper,
  Stack,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    borderLeftWidth: 2,
    borderColor: "#ffecde",
    marginLeft: "4px",
  },
}));

const CustomStepIcon = () => (
  <div
    style={{
      borderColor: "#ffecde",
      border: "1px solid #ffecde",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "35px",
      height: "35px",
    }}
  >
    <div
      style={{
        borderColor: "#ffc59b",
        border: "1px solid #ffc59b",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "25px",
        height: "25px",
      }}
    >
      <div
        style={{
          width: "15px",
          height: "15px",
          borderRadius: "50%",
          backgroundColor: "#FE9E58",
        }}
      />
    </div>
  </div>
);

function Listings({ title, data = [] }: any) {
  return (
    <Container sx={{ my: 3 }}>
      <Paper component={Container} sx={{ py: 2.5, mb: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h4">{title}</Typography>
          <Link href="#" underline="none" color="textSecondary">
            View all
          </Link>
        </Stack>
      </Paper>

      <Paper component={Container} sx={{ py: 3 }}>
        <Stepper orientation="vertical" connector={<CustomStepConnector />}>
          {data?.map((elem: any, i: any) => {
            const date = new Date(elem?.actionDate);
            const formattedDate = `${("0" + date?.getDate())?.slice(-2)}.${(
              "0" +
              (date?.getMonth() + 1)
            )?.slice(-2)}.${date?.getFullYear()}`;

            return (
              <Step key={`${i}`}>
                <StepLabel StepIconComponent={CustomStepIcon}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <h1
                      style={{
                        fontSize: "0.875rem",
                        lineHeight: "1.43",
                        fontWeight: "400",
                      }}
                    >
                      {elem?.action?.description ||
                        `Person ${i} ${title} in Sector 1`}
                    </h1>
                    <h1
                      style={{
                        fontSize: "0.875rem",
                        lineHeight: "1.43",
                        fontWeight: "400",
                      }}
                    >
                      {formattedDate || "18.06.2024 13:45"}
                    </h1>
                  </div>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Paper>
    </Container>
  );
}

export { Listings };
