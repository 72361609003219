import { useQuery, gql } from "@apollo/client";
import { Practice } from "../../types";

type PracticesCollectionData = {
  practices: Practice[];
};

const fetchPracticesDocuments = gql`
  query ($limit: Int) {
    practiceDocuments(limit: $limit) {
      createdAt
      filename
      id
      path
    }
  }
`;

const usePracticesDocumentsCall = (limit: any) => {
  const { data, loading } = useQuery<PracticesCollectionData>(
    fetchPracticesDocuments,
    {
      fetchPolicy: "cache-and-network",
      variables: limit,
    }
  ) as any;

  return {
    practiceDocuments: data?.practiceDocuments,
    loading,
  };
};

export { usePracticesDocumentsCall };
