import { useMemo } from "react";
import { useQuery, gql } from "@apollo/client";

export type PolicyStatus = "lettresSectorielles" | "other" | "décrets" | "lois";

export type PolicyData = {
  id: string | number;
  title: string;
  description: string;
  picture?: string;
  status: PolicyStatus;
  policyCategory: { name: string };
};

export type PoliciesCollectionData = {
  policies: { collection: PolicyData[]; metadata: {} };
};
// policies(limit: $limit) {
//   id
//   title
//   description
//   picture
//   status
//   policyCategory {
//     name
//   }
// }

// countryId: 1
// themeId: 1
// startYear: 2019
// endYear: 2024
// page: 1
const policiesQueryDocument = gql`
  query policiesQuery($limit: Int, $page: Int) {
    policies(limit: $limit, page: $page) {
      collection {
        country {
          name
        }
        createdAt
        date
        description
        document
        geographicalArea {
          name
        }
        id
        link
        picture
        policyCategory {
          name
        }
        status
        theme {
          id
          title
        }
        title
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

// {
//   policies(page: 1, limit: 10 countryId: 1 themeId: 1 startYear: 2019 endYear: 2024) {
//     collection {
//       country{name}
//       createdAt
//       date
//       description
//       document
//       geographicalArea{name}
//       id
//       link
//       picture
//       policyCategory{name}
//       status
//       theme{id
//             title
//            }
//       title
//       updatedAt
//     }
//     metadata {
//       currentPage
//       limitValue
//       totalCount
//       totalPages
//     }
//   }
// }

const usePublicPoliciesCall = (
  limit: any,
  policyStatus?: PolicyStatus | "all"
) => {
  const { data, loading, refetch } = useQuery<PoliciesCollectionData>(
    policiesQueryDocument,
    { fetchPolicy: "network-only", variables: limit }
  );

  const dist = useMemo(() => {
    const dist = {
      lois: [] as PolicyData[],
      decrets: [] as PolicyData[],
      lettresSectorielles: [] as PolicyData[],
      other: [] as PolicyData[],
    };

    data?.policies?.collection?.forEach((p) => {
      switch (p?.policyCategory?.name?.toLowerCase()) {
        case "lois":
          dist.lois.push(p);
          break;
        case "décrets":
          dist.decrets.push(p);
          break;
        case "lettresSectorielles":
          dist.lettresSectorielles.push(p);
          break;
        case "other":
        default:
          dist.other.push(p);
          break;
      }
    });

    return dist;
  }, [data?.policies?.collection]);

  const policies = (() => {
    switch (policyStatus) {
      case "lois":
        return dist.lois;
      case "décrets":
        return dist.decrets;
      case "lettresSectorielles":
        return dist.lettresSectorielles;
      case "other":
        return dist.other;
      default:
        return data?.policies?.collection || [];
    }
  })();

  return {
    policies,
    dist: {
      all: data?.policies?.collection || [],
      ...dist,
    },
    loading,
    refetch,
    metadata: data?.policies?.metadata,
  };
};

export { usePublicPoliciesCall };
