import {
  Alert,
  Avatar,
  Button,
  Paper,
  Snackbar,
  SnackbarCloseReason,
  Typography,
} from "@mui/material";
import { AttachmentUploadIcon } from "../../icons";
import Stack from "@mui/material/Stack";
import styled from "@emotion/styled";
import { useState } from "react";
import { ConditionalRenderer } from "../conditionalRenderer";
import { imageCheck, onlyPDF } from "./helpers";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function Attachment({
  setAttachmentDetails,
  attachmentDetails,
  allowedFormats,
  text,
  variant,
  isDocument = false,
}: any) {
  const [message, setMessage] = useState("");

  function handleOnChange(event: any) {
    const file = event.target.files[0];
    if (!file) return;

    const isAllowedFormat = allowedFormats.some((format: string) =>
      file?.type?.toLowerCase()?.includes(format?.toLowerCase())
    );

    if (!isAllowedFormat) {
      setAttachmentDetails({});
      return setMessage("Please Upload the Correct Format.");
    }

    // for image attachment is below handler
    imageCheck({ file, setMessage, setAttachmentDetails });

    // for PDF attachment is below handler
    if (allowedFormats?.includes("pdf") && file) {
      onlyPDF({ file, setMessage, setAttachmentDetails });
      return;
    }
  }

  function handleDrop(event: any) {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (!file) return;

    const isAllowedFormat = allowedFormats.some((format: string) =>
      file?.type?.toLowerCase()?.includes(format?.toLowerCase())
    );

    if (!isAllowedFormat) {
      setAttachmentDetails({});
      return setMessage("Please Upload the Correct Format.");
    }

    // for document attachment is below handler
    if (isDocument) {
      onlyPDF({ file, setMessage, setAttachmentDetails });
      return;
    }

    // for image attachment is below handler
    imageCheck({ file, setMessage, setAttachmentDetails });

    // for PDF attachment is below handler
    if (allowedFormats?.includes("pdf") && file) {
      onlyPDF({ file, setMessage, setAttachmentDetails });
      return;
    }
  }

  function handleDragOver(event: any) {
    event.preventDefault();
  }

  function handleClose(
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) {
    if (reason === "clickaway") {
      return;
    }

    setMessage("");
  }

  return (
    <>
      <Snackbar
        open={message ? true : false}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Button
        component="label"
        role={undefined}
        tabIndex={-1}
        sx={{ width: "100%", padding: "0px" }}
      >
        <VisuallyHiddenInput type="file" onChange={handleOnChange} />
        <Paper
          component={Stack}
          sx={{ py: variant?.py || 6, mb: 2, width: "100%" }}
          alignItems="center"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          variant={variant?.variant || ""}
        >
          <Avatar sx={{ mb: 2 }}>
            <AttachmentUploadIcon />
          </Avatar>
          <ConditionalRenderer condition={!attachmentDetails?.name}>
            <Typography mb={0.5}>Click to upload or drag and drop</Typography>
            <Typography variant="caption" color="textSecondary">
              {text}
            </Typography>
          </ConditionalRenderer>
          <ConditionalRenderer condition={attachmentDetails?.name}>
            <Typography mb={0.5}>{attachmentDetails?.name}</Typography>
            <Typography variant="caption" color="textSecondary">
              {text}
            </Typography>
          </ConditionalRenderer>
        </Paper>
      </Button>
    </>
  );
}

export { Attachment };
