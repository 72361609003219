"use client";

import {
  AreaChart,
  CartesianGrid,
  Area,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

function AreaCharts({}: any) {
  const data = [
    {
      name: "Jan",
      amt: 1000,
    },
    {
      name: "Feb",
      amt: 900,
    },
    {
      name: "Mar",
      amt: 700,
    },
    {
      name: "Apr",
      amt: 800,
    },
    {
      name: "May",
      amt: 500,
    },
    {
      name: "Jun",
      amt: 400,
    },
    {
      name: "Jul",
      amt: 300,
    },
    {
      name: "Aug",
      amt: 200,
    },
    {
      name: "Sep",
      amt: 100,
    },
    {
      name: "Oct",
      amt: 50,
    },
    {
      name: "Nov",
      amt: 700,
    },
    {
      name: "Dec",
      amt: 600,
    },
  ];
  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart width={500} height={400} data={data}>
          <CartesianGrid strokeDasharray="none" vertical={false} />
          <XAxis
            dataKey="name"
            tick={{ fill: "#9da1a4" }}
            label={{
              value: "Month",
              fill: "#989c9f",
              dy: 10,
            }}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tick={{ fill: "#9da1a4" }}
            label={{
              value: "Stat Name",
              angle: -90,
              dy: 15,
              position: "insideLeft",
              fill: "#989c9f",
            }}
            axisLine={false}
            tickLine={false}
          />
          <defs>
            <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
              <feDropShadow
                dx="0"
                dy="3"
                stdDeviation="4"
                floodColor="#e6e2d9"
                floodOpacity="1"
              />
            </filter>
          </defs>
          <Area
            type="monotone"
            dataKey="amt"
            stroke="#F9B500"
            fill="#fffbf0"
            strokeWidth={10}
            filter="url(#shadow)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
}

export { AreaCharts };
