import React, { useMemo, useState } from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import CTAContainer from "../../@lib/components/@common/CTAContainer";

import { useAuthSession } from "../../@lib/components/AuthSessionProvider";
import {  PeopleListCard } from "../components";
import { Header } from "../../@lib/components";
import { usePeoplesCall } from "../../@lib/hooks";
import {
  ConditionalRenderer,
  PublicAndPolicyFilters,
} from "../../@lib/components";
import { useFilters } from "../helpers";

const rowSkeleton = <Skeleton variant="rounded" height={150} />;

function PeopleList() {
  const [currTab, setCurrTab] = useState("all");

  const { user } = useAuthSession();
  const isLoggedIn = !!user;

  const location = useLocation();
  const isPrivate = location?.pathname?.includes("/submission/people") || false;

  const { data } = usePeoplesCall({ isPrivate, user });

  const { tabsData, people = [] }: any = useFilters({ isPrivate, data });

  const filteredDataResults = useMemo(() => {
    if (currTab === "all") return people;
    return people?.filter(
      (item: any) => item.status?.toLowerCase() === currTab
    );
  }, [people, currTab]);

  const modifiedData = isPrivate ? filteredDataResults : data?.people;

  return (
    <div>
      <Header title="People" subtitle="NGO Detail will goes here, we can utilize double line text as well, No image required due to good UX" />

      <Container sx={{ my: 3 }}>
        <ConditionalRenderer condition={isPrivate}>
          <PublicAndPolicyFilters
            currTab={currTab}
            setCurrTab={setCurrTab}
            listItems={tabsData}
            heading="List of Peoples data"
            isAllShow={isPrivate ? true : false}
          />
        </ConditionalRenderer>
        <Stack gap={2}>
          {modifiedData?.map((elem: any, i: any) => (
            <PeopleListCard
              key={`people-list-card-${elem.id}`}
              elem={elem}
              isPrivate={isPrivate}
            />
          ))}
        </Stack>
      </Container>

      <Container sx={{ my: 3 }}>
        <Paper sx={{ py: 2, display: "flex", justifyContent: "center" }}>
          <Link color="textSecondary" underline="none">
            Show more
          </Link>
        </Paper>
      </Container>

      <ConditionalRenderer condition={isLoggedIn && isPrivate}>
        <Container sx={{ my: 3 }}>
          <CTAContainer sx={{ textAlign: "center" }}>
            <Typography variant="subtitle1" mb={2}>
              Add new people to the platform
            </Typography>
            <Button
              component={RouterLink}
              to="/people/new"
              sx={{
                px: 6,
                backgroundColor: "#ffffff",
                color: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                },
              }}
            >
              Add People
            </Button>
          </CTAContainer>
        </Container>
      </ConditionalRenderer>
    </div>
  );
}

export { PeopleList };
