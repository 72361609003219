import React from "react";
import type { Practice } from "../../@lib/types";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import Button from "@mui/material/Button";
import CTAContainer from "../../@lib/components/@common/CTAContainer";

import { useState } from "react";
import { useAuthSession } from "../../@lib/components/AuthSessionProvider";
import {
  CardAll,
  ConditionalRenderer,
  Header,
  PublicAndPolicyFilters,
} from "../../@lib/components";
import { usePublicPracticesCall } from "../../@lib/hooks";

const PracticesList: React.FC = () => {
  const { user } = useAuthSession();
  const isLoggedIn = !!user;
  const [currTabIndex, setCurrTabIndex] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const { practices, dist, loading, metadata }: any = usePublicPracticesCall(
    { limit: 8, page: pageNumber },
    currTabIndex
  );

  function onClickHandler() {
    if (pageNumber === metadata?.totalPages) return;
    setPageNumber(pageNumber + 1);
  }

  const publicTabs = [
    {
      value: "all",
      count: dist?.all?.length,
      title: "All",
    },
    {
      value: "chainActorPractices",
      count: dist?.chainActorPractices?.length,
      title: "Pratiques des acteurs de la chaîne",
    },
    {
      value: "finance",
      count: dist?.finance?.length,
      title: "Normes",
    },
    {
      value: "regulatory",
      count: dist?.regulatory?.length,
      title: "Pratiques réglementaires",
    },
    {
      value: "community",
      count: dist?.community?.length,
      title: "Pratiques communautaires",
    },
    {
      value: "normesdegestiondesboues",
      count: dist?.normesdegestiondesboues?.length,
      title: "Normes de gestion des boues",
    },
    {
      value: "normesdegestiondeseauxusées",
      count: dist?.normesdegestiondeseauxusees?.length,
      title: "Normes de gestion des eaux usées",
    },
  ];

  const hasMore = pageNumber === metadata?.totalPages;

  return (
    <div>
      <Header
        title="Practices"
        subtitle="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
      />

      <Container sx={{ my: 3 }}>
        <PublicAndPolicyFilters
          dist={dist}
          currTab={currTabIndex}
          setCurrTab={setCurrTabIndex}
          listItems={publicTabs}
          heading="List of practices data"
          isAllShow={true}
        />

        <Stack gap={2}>
          {practices.map((p: any, i: any) => (
            <CardAll
              key={`practice-list-card-${i}`}
              p={p}
              loading={loading}
              path={`/practices/${p.id}`}
            />
          ))}
        </Stack>
      </Container>

      <ConditionalRenderer condition={!hasMore}>
        <Container sx={{ my: 3 }}>
          <Paper sx={{ py: 2, display: "flex", justifyContent: "center" }}>
            <Button onClick={onClickHandler}>Show more</Button>
          </Paper>
        </Container>
      </ConditionalRenderer>

    </div>
  );
};

export default PracticesList;
