import React from "react";
import type { Practice } from "../../../@lib/types";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CTAContainer from "../../../@lib/components/@common/CTAContainer";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import Fade from "@mui/material/Fade";
import AppBar from "../../../@lib/components/@common/AppBar";
import SearchBar from "../../../@lib/components/@common/SearchBar";
import PageTitle from "../../../@lib/components/@common/PageTitle";
import PolicyFilters from "../../../@lib/components/PolicyFilters";
import PracticeCard, {
  practiceCardSkeleton,
} from "../../../@lib/components/PracticeCard";

import { useState, useMemo, useCallback } from "react";
import { useAuthSession } from "../../../@lib/components/AuthSessionProvider";
import { useMutation, gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useNotify } from "../../../@lib/components/@common/NotificationsProvider";
import {
  Attachment,
  Card,
  ConditionalRenderer,
  LatestReport,
  PublicAndPolicyFilters,
} from "../../../@lib/components";
import { usePrivatePracticesCall } from "../../../@lib/hooks/usePrivatePracticesCall";
import { usePracticesDocumentsCall } from "../../../@lib/hooks";

export type PracticesCollectionData = {
  practices: Practice[];
};

const deletePracticeMutationDocument = gql`
  mutation DeletePractice($practiceInput: PracticeDeleteInput!) {
    practiceDelete(input: $practiceInput) {
      practice {
        id
      }
    }
  }
`;

const PrivatePractices: React.FC = () => {
  const { practiceDocuments } = usePracticesDocumentsCall({}) as any;
  const [attachmentDetails, setAttachmentDetails] = useState({ name: "" });
  const notify = useNotify();
  const navigate = useNavigate();
  const { user } = useAuthSession();
  const isLoggedIn = !!user;
  const [deletePractice, { loading: deletingPractice }] = useMutation(
    deletePracticeMutationDocument
  );
  const [currTabIndex, setCurrTabIndex] = useState(0);
  const { practices, dist, loading } = usePrivatePracticesCall(
    user,
    currTabIndex
  );

  /**
   * handleDeletePractice()
   */
  const handleDeletePractice = useCallback(
    async (practice: Practice) => {
      if (
        await notify.confirm(
          `Deleting practice "${practice.title}". This cannot be undone.`
        )
      ) {
        try {
          await deletePractice({
            variables: {
              practiceInput: {
                id: practice.id,
              },
            },
          });

          notify.success(`Practice "${practice.title}" deleted successfuly!`);
        } catch (err) {
          notify.error(err as Error);
        }
      }
    },
    [deletePractice, notify]
  );

  /**
   * handleEditPractice()
   */
  const handleEditPractice = useCallback(
    (practice: Practice) => navigate(`/practices/${practice.id}/edit`),
    [navigate]
  );

  const privateTab = [
    {
      value: "all",
      count: dist?.all?.length,
      title: "All",
    },
    {
      value: "approved",
      count: dist.approved.length,
      title: "Approved",
    },
    {
      value: "pending",
      count: dist.pending.length,
      title: "Pending",
    },
    {
      value: "rejected",
      count: dist.rejected.length,
      title: "Rejected",
    },
  ];

  return (
    <Box pb={4}>
      <AppBar>
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          justifyContent="space-between"
          flexGrow={1}
        >
          <PageTitle title="Practices" subtitle="Lorem ipsum dolor sit amet." />
          <SearchBar placeholder="Search tree and keyword" />
        </Stack>
        {loading && (
          <LinearProgress
            sx={{ position: "absolute", left: 0, right: 0, bottom: 0 }}
          />
        )}
      </AppBar>

      <Container sx={{ my: 5 }}>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant="body2" color="textSecondary">
            Filter by:
          </Typography>
          <PolicyFilters />
        </Stack>
      </Container>

      <Container sx={{ my: 3 }}>
        <PublicAndPolicyFilters
          dist={dist}
          currTab={currTabIndex}
          setCurrTab={setCurrTabIndex}
          listItems={privateTab}
          heading="List of practices data"
        />

        <Grid container spacing={2}>
          {practices.map((p, i) => (
            <Grid key={`${i}`} item xs={12} md={6} lg={3}>
              <Slide in timeout={180 * (i + 1)} direction="right">
                <Card
                  {...p}
                  path={`/practices/${p?.id}`}
                  editPath={`/practices/${p.id}/edit`}
                  disabled={loading || deletingPractice}
                  onDelete={handleDeletePractice}
                  onEdit={handleEditPractice}
                  sx={{ height: "100%" }}
                  isLoggedIn={isLoggedIn}
                  isPrivate
                />
              </Slide>
            </Grid>
          ))}

          {loading &&
            practices.length === 0 &&
            Array(4)
              .fill(null)
              .map((_, i) => (
                <Fade key={`${i}`} in timeout={180 * (i + 1)}>
                  <Grid item xs={12} md={6} lg={3}>
                    {practiceCardSkeleton}
                  </Grid>
                </Fade>
              ))}
        </Grid>
      </Container>

      {isLoggedIn && (
        <Container sx={{ my: 3 }}>
          <Fade in timeout={800}>
            <CTAContainer sx={{ textAlign: "center" }}>
              <Typography variant="subtitle1" mb={2}>
                Add new practices to the platform
              </Typography>
              <Button
                component={RouterLink}
                to="new"
                sx={{
                  px: 6,
                  backgroundColor: "#ffffff",
                  color: "primary.dark",
                  "&:hover": {
                    backgroundColor: "primary.light",
                  },
                }}
              >
                CREATE
              </Button>
            </CTAContainer>
          </Fade>
        </Container>
      )}

      <LatestReport
        items={practiceDocuments}
        path="/practices/viewAllReports"
      />

      <ConditionalRenderer condition={isLoggedIn}>
        <Container sx={{ my: 3 }}>
          <Paper component={Container} sx={{ py: 3, mb: 2 }}>
            <Typography variant="h4">Data Import</Typography>
          </Paper>

          <Attachment
            allowedFormats={["pdf"]}
            text="PDF file (max. 50mb)"
            setAttachmentDetails={setAttachmentDetails}
            attachmentDetails={attachmentDetails}
          />
          <Box textAlign="right">
            <Button variant="contained" sx={{ px: 6 }} disabled>
              Import
            </Button>
          </Box>
        </Container>
      </ConditionalRenderer>
    </Box>
  );
};

export default PrivatePractices;
