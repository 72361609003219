import React from "react";
import AuthLayout from "./@lib/components/AuthLayout";
import DrawerLayout from "./@lib/components/@common/DrawerLayout";
import Login from "./auth/Login";
import Dashboard from "./Dashboard";
import Indicators from "./Indicators";
import PoliciesHome from "./policies/PoliciesHome";
import PoliciesList from "./policies/PoliciesList";
import PolicyNew from "./policies/PolicyNew";
import PolicyEdit from "./policies/PolicyEdit";
import PolicyDetails from "./policies/PolicyDetails";
import PracticesHome from "./practices/PracticesHome";
import PracticesList from "./practices/PracticesList";
import PracticeNew from "./practices/PracticeNew";
import PracticeEdit from "./practices/PracticeEdit";
import PracticeDetails from "./practices/PracticeDetails";
import Support from "./Support";
import AccountSettings from "./AccountSettings";
import { Navigate } from "react-router-dom";
import MainSideNav from "./@lib/components/MainSideNav";
import FooterPublic from "./@lib/components/FooterPublic";

import { createBrowserRouter } from "react-router-dom";
import { useAuthSession } from "./@lib/components/AuthSessionProvider";
import PrivatePolicies from "./policies/PoliciesHome/privatePolicies";
import PrivatePractices from "./practices/PracticesHome/privatePractices";
import { PrivatePoliciesList } from "./policies/PrivatePoliciesList";
import { PrivatePracticesList } from "./practices/PrivatePracticesList";
import { AllReports } from "./@lib/components";
import { PeopleHome, PeopleList, PersonDetail, PersonNew } from "./people";

type AuthGuardProps = {
  policy: "require_auth" | "require_no_auth";
  redirectTo: string;
  children?: React.ReactNode;
};

const AuthGuard: React.FC<AuthGuardProps> = (props) => {
  const { user } = useAuthSession();
  const isAuthenticated = !!user;
  switch (props.policy) {
    case "require_auth": {
      if (isAuthenticated) return <>{props.children}</>;
      else return <Navigate to={props.redirectTo} />;
    }
    case "require_no_auth": {
      if (!isAuthenticated) return <>{props.children}</>;
      else return <Navigate to={props.redirectTo} />;
    }
    default: {
      return <>{props.children}</>;
    }
  }
};

const router = createBrowserRouter([
  {
    path: "",
    element: (
      <DrawerLayout
        drawerContent={<MainSideNav />}
        footerContent={<FooterPublic />}
      />
    ),
    children: [
      { index: true, element: <Dashboard /> },
      { path: "indicators", element: <Indicators /> },
      { path: "policies", element: <PoliciesHome /> },
      { path: "policies/all", element: <PoliciesList /> },
      { path: ":module/viewAllReports", element: <AllReports /> },
      { path: "policies/:policyId", element: <PolicyDetails /> },

      { path: "practices", element: <PracticesHome /> },
      { path: "practices/all", element: <PracticesList /> },
      { path: "practices/:practiceId", element: <PracticeDetails /> },

      { path: "people", element: <PeopleHome /> },
      { path: "personDetail", element: <PersonDetail /> },
      { path: "people/all", element: <PeopleList /> },

      { path: "support", element: <Support /> },

      // protected nested route

      {
        path: "practices/:practiceId/edit",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PracticeEdit />
          </AuthGuard>
        ),
      },

      {
        path: "account-settings",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <AccountSettings />
          </AuthGuard>
        ),
      },
      {
        path: "submission/people",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PeopleHome />
          </AuthGuard>
        ),
      },
      {
        path: "submission/people/new",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PersonNew />
          </AuthGuard>
        ),
      },
      {
        path: "submission/people/all",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PeopleList />
          </AuthGuard>
        ),
      },

      {
        path: "submission/policies",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PrivatePolicies />
          </AuthGuard>
        ),
      },
      {
        path: "submission/policies/all",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PrivatePoliciesList />
          </AuthGuard>
        ),
      },
      {
        path: "submission/policies/new",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PolicyNew />
          </AuthGuard>
        ),
      },
      {
        path: "policies/:policyId/edit",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PolicyEdit />
          </AuthGuard>
        ),
      },

      {
        path: "submission/practices",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PrivatePractices />
          </AuthGuard>
        ),
      },
      {
        path: "submission/practices/new",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PracticeNew />
          </AuthGuard>
        ),
      },
      {
        path: "submission/practices/all",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PrivatePracticesList />
          </AuthGuard>
        ),
      },
    ],
  },
  // {
  //   path: "",
  //   // element: (
  //   //   <AuthGuard policy="require_auth" redirectTo="/auth/login">
  //   //     <DrawerLayout drawerContent={<MainSideNav />} />
  //   //   </AuthGuard>
  //   // ),
  //   children: [

  //     { path: "practices/:practiceId/edit", element: <PracticeEdit /> },

  //     { path: "account-settings", element: <AccountSettings /> },
  //     { path: "submission/people", element: <PeopleHome /> },
  //     { path: "submission/people/new", element: <PersonNew /> },
  //     { path: "submission/people/all", element: <PeopleList /> },

  //     { path: "submission/policies", element: <PrivatePolicies /> },
  //     { path: "submission/policies/all", element: <PrivatePoliciesList /> },
  //     { path: "submission/policies/new", element: <PolicyNew /> },
  //     { path: "policies/:policyId/edit", element: <PolicyEdit /> },

  //     { path: "submission/practices", element: <PrivatePractices /> },
  //     { path: "submission/practices/new", element: <PracticeNew /> },
  //     { path: "submission/practices/all", element: <PrivatePracticesList /> },
  //   ],
  // },
  {
    path: "auth",
    element: (
      <AuthGuard policy="require_no_auth" redirectTo="/">
        <AuthLayout />
      </AuthGuard>
    ),
    children: [
      { index: true, element: <Navigate to="login" /> },
      { path: "login", element: <Login /> },
    ],
  },
]);

export default router;
