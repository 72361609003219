import React from "react";
import type { Practice } from "../../../@lib/types";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import Fade from "@mui/material/Fade";
import PracticeCard, {
  practiceCardSkeleton,
} from "../../../@lib/components/PracticeCard";

import { useState } from "react";
import {
  Card,
  Header,
  LatestReport,
  PublicAndPolicyFilters,
} from "../../../@lib/components";
import {
  usePracticesDocumentsCall,
  usePublicPracticesCall,
} from "../../../@lib/hooks";

export type PracticesCollectionData = {
  practices: Practice[];
};

const PublicPractices: React.FC = () => {
  const [currTabIndex, setCurrTabIndex] = useState(0);
  const {
    practices = [],
    dist,
    loading,
  } = usePublicPracticesCall({ limit: 8 }, currTabIndex);
  const { practiceDocuments } = usePracticesDocumentsCall({}) as any;

  const publicTabs = [
    {
      value: "all",
      count: dist?.all?.length,
      title: "All",
    },
    {
      value: "chainActorPractices",
      count: dist?.chainActorPractices?.length,
      title: "Pratiques des acteurs de la chaîne",
    },
    {
      value: "finance",
      count: dist?.finance?.length,
      title: "Normes",
    },
    {
      value: "regulatory",
      count: dist?.regulatory?.length,
      title: "Pratiques réglementaires",
    },
    {
      value: "community",
      count: dist?.community?.length,
      title: "Pratiques communautaires",
    },
    {
      value: "normesdegestiondesboues",
      count: dist?.normesdegestiondesboues?.length,
      title: "Normes de gestion des boues",
    },
    {
      value: "normesdegestiondeseauxusées",
      count: dist?.normesdegestiondeseauxusees?.length,
      title: "Normes de gestion des eaux usées",
    },
  ];

  return (
    <Box pb={4}>
      <Header title="Practices" subtitle="Lorem ipsum dolor sit amet." />
      <Container sx={{ my: 3 }}>
        <PublicAndPolicyFilters
          dist={dist}
          currTab={currTabIndex}
          setCurrTab={setCurrTabIndex}
          listItems={publicTabs}
          heading="List of practices data"
        />

        <Grid container spacing={2}>
          {practices?.map((p, i) => (
            <Grid key={`${i}`} item xs={12} md={6} lg={3}>
              <Slide in timeout={180 * (i + 1)} direction="right">
                <Card
                  {...p}
                  path={`/practices/${p?.id}`}
                  disabled={loading}
                  sx={{ height: "100%" }}
                  isPrivate={false}
                />
                {/* <PracticeCard
                  practice={p}
                  disabled={loading || deletingPractice}
                  onDelete={handleDeletePractice}
                  onEdit={handleEditPractice}
                  sx={{ height: "100%" }}
                  isPrivate={false}
                /> */}
              </Slide>
            </Grid>
          ))}

          {loading &&
            practices.length === 0 &&
            Array(4)
              .fill(null)
              .map((_, i) => (
                <Fade key={`${i}`} in timeout={180 * (i + 1)}>
                  <Grid item xs={12} md={6} lg={3}>
                    {practiceCardSkeleton}
                  </Grid>
                </Fade>
              ))}
        </Grid>
      </Container>

      <LatestReport
        items={practiceDocuments}
        path="/practices/viewAllReports"
      />
    </Box>
  );
};

export default PublicPractices;
