
import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Outlet } from 'react-router-dom';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

export type DrawerLayoutProps = {
  drawerWidth?: number;
  drawerContent?: React.ReactNode;
  footerContent?: React.ReactNode;
};

const DrawerLayout:React.FC<DrawerLayoutProps> = (props) => {
  const {
    drawerWidth=280,
    drawerContent,
    footerContent
  } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box display={{md: 'flex'}}>
      <Drawer
        sx={{
          minWidth: 0,
          width: drawerWidth,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            border: 'none'
          }
        }}
        PaperProps={{ elevation: 5 }}
        anchor="left"
        variant={isSmallScreen ? 'temporary' : 'permanent'}
      >{drawerContent}</Drawer>
      <Box flexGrow={{ md: 1 }} minWidth={0}>
        <Outlet />
        {footerContent}
      </Box>
    </Box>
  );
};

export default DrawerLayout;
