import { useMemo } from "react";

function useFilters({ isPrivate, data }: any) {
  const dist = useMemo(() => {
    const dist = {
      approved: [] as any,
      rejected: [] as any,
      pending: [] as any,
    };

    if (!isPrivate) return dist;

    data?.people.forEach((p: any) => {
      switch (p.status?.toLowerCase()) {
        case "rejected":
          dist.rejected.push(p);
          break;
        case "approved":
          dist.approved.push(p);
          break;
        case "pending":
        default:
          dist.pending.push(p);
          break;
      }
    });

    return dist;
  }, [data, isPrivate]);

  const tabsData = [
    {
      value: "all",
      count: data?.people.length,
      title: "All",
    },
    {
      value: "approved",
      count: dist?.approved.length,
      title: "Approved",
    },
    {
      value: "pending",
      count: dist?.pending.length,
      title: "Pending",
    },
    {
      value: "rejected",
      count: dist?.rejected.length,
      title: "Rejected",
    },
  ];
  return { tabsData, people: data?.people };
}
export { useFilters };
