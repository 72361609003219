
import type { FC } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import AppLogo from '../AppLogo';
import I18nSelect from '../@common/I18nSelect';
import { FaSquareFacebook, FaLinkedin, FaSquareXTwitter } from "react-icons/fa6";

const FooterPublic:FC = () => {
  return (
    <Paper component={Container} square>
      <Stack direction={{ lg: 'row' }} alignItems={{ xs: 'center', lg: 'flex-start' }} gap={3} sx={{ pt: 3, pb: 10 }}>
        <Box sx={{ flex: 2, textAlign: {xs: 'center', lg: 'left'} }}>
          <AppLogo size="small" />
          <Typography color="textSecondary" sx={{ mt: 1 }}>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Itaque eum tempore voluptatum accusantium.
          </Typography>
        </Box>
        <Box sx={{ flex: 3 }} />
        <Box sx={{ flex: 3 }}>
          <Stack direction={{ md: 'row' }} gap={{ xs: 5, lg: 10 }} justifyContent="flex-end">
            <Stack gap={1} alignItems={{ xs: 'center', lg: 'flex-start' }}>
              <Typography color="textSecondary" mb={2}>Solutions</Typography>
              <Link component={RouterLink} to="/policies" underline="hover" color="inherit">Policies</Link>
              <Link component={RouterLink} to="/practices" underline="hover" color="inherit">Practices</Link>
            </Stack>

            <Stack gap={1} alignItems={{ xs: 'center', lg: 'flex-start' }}>
              <Typography color="textSecondary" mb={2}>Company</Typography>
              <Link href="#" underline="hover" color="inherit">About</Link>
              <Link href="#" underline="hover" color="inherit">Blog</Link>
            </Stack>
            
            <Stack gap={1} alignItems={{ xs: 'center', lg: 'flex-start' }}>
              <Typography color="textSecondary" mb={2}>Contact</Typography>
              <Typography>Phone number</Typography>
              <Typography>Phone number 2</Typography>
              <Typography>Email</Typography>
              <Typography>Address</Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <Divider sx={{ opacity: 0.75 }}/>
      <Stack direction={{ lg: 'row' }} gap={5} alignItems="center" sx={{ py: 3 }}>
        <Box>
          <Typography variant="caption">Copyright &copy; 2024 Company Name - All rights reserved.</Typography>
        </Box>
        <Stack direction="row" gap={3}>
          <Link href="#" underline="hover" variant="caption" color="inherit">Privacy Policy</Link>
          <Link href="#" underline="hover" variant="caption" color="inherit">Terms &amp; Conditions</Link>
        </Stack>
        <Box flexGrow={1} />
        <Stack direction="row" gap={3} alignItems="center" sx={{ color: '#878787' }}>
          <Link href="https://www.facebook.com" target="_BLANK" underline="none" color="inherit"><FaSquareFacebook size={32} /></Link>
          <Link href="https://www.linkedin.com" target="_BLANK" underline="none" color="inherit"><FaLinkedin size={32} /></Link>
          <Link href="https://www.x.com" target="_BLANK" underline="none" color="inherit"><FaSquareXTwitter size={32} /></Link>
          <I18nSelect
            value="en"
            options={[
              {label: 'English', value: 'en'}
            ]}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default FooterPublic;
