import {
  Box,
  Button,
  Container,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import PageTitle from "../@common/PageTitle";
import SearchBar from "../@common/SearchBar";
import AppBar from "../@common/AppBar";
import { LatestReport } from "../latestReport";
import { useParams } from "react-router-dom";
import { useDataCall } from "./dataCall";
import PolicyFilters from "../PolicyFilters";
import { useState } from "react";
import { ConditionalRenderer } from "../conditionalRenderer";

function AllReports() {
  const [pageNumber, setPageNumber] = useState(1);

  const { module } = useParams();
  const { data } = useDataCall({
    module: module,
    limit: {
      limit: 4,
      page: pageNumber,
    },
  }) as any;

  function onClickHandler() {
    if (pageNumber === data?.policyDocuments.metadata.totalPages) return;
    setPageNumber(pageNumber + 1);
  }

  const hasMore = pageNumber === data?.policyDocuments.metadata.totalPages;

  return (
    <Box pb={3}>
      <AppBar>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexGrow={1}
        >
          <PageTitle
            title="Reports"
            subtitle="NGO Detail will goes here, we can utilize double line text as well, No image required due to good UX."
          />
          <SearchBar placeholder="Search tree and keyword" />
        </Stack>
      </AppBar>
      <Container sx={{ my: 5 }}>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant="body2" color="textSecondary">
            Filter by:
          </Typography>
          <PolicyFilters />
        </Stack>
      </Container>
      <LatestReport
        isAllShow={false}
        items={
          data?.policyDocuments?.collection ||
          data?.practiceDocuments?.collection ||
          []
        }
      />
      <ConditionalRenderer condition={!hasMore}>
        <Paper sx={{ py: 2, display: "flex", justifyContent: "center" }}>
          <Button onClick={onClickHandler}>Show more</Button>
        </Paper>
      </ConditionalRenderer>
    </Box>
  );
}

export { AllReports };
