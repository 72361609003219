import { useQuery, gql } from "@apollo/client";
import { Practice } from "../../types";

type PracticesCollectionData = {
  practices: Practice[];
};

const fetchPoliciesDocuments = gql`
  query ($limit: Int) {
    policyDocuments(limit: $limit) {
      createdAt
      filename
      id
      path
    }
  }
`;

const usePoliciesDocumentsCall = (limit: any) => {
  const { data, loading } = useQuery<PracticesCollectionData>(
    fetchPoliciesDocuments,
    {
      fetchPolicy: "cache-and-network",
      variables: limit,
    }
  ) as any;

  return {
    policyDocuments: data?.policyDocuments,
    loading,
  };
};

export { usePoliciesDocumentsCall };
