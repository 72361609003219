import { useMemo } from "react";
import { useQuery, gql } from "@apollo/client";

export type PolicyStatus = "pending" | "approved" | "rejected";

export type PolicyData = {
  id: string | number;
  title: string;
  description: string;
  picture?: string;
  status: PolicyStatus;
};

export type PoliciesCollectionData = {
  policies: { collection: PolicyData[]; metadata: {totalPages:0} };
};

const policiesQueryDocument = gql`
  query policiesQuery($limit: Int, $page: Int) {
    policies(limit: $limit, page: $page) {
      collection {
        country {
          name
        }
        createdAt
        date
        description
        document
        geographicalArea {
          name
        }
        id
        link
        picture
        policyCategory {
          name
        }
        status
        theme {
          id
          title
        }
        title
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

const usePrivatePoliciesCall = ({
  user,
  policyStatus,
  variables,
}: {
  user: any;
  policyStatus?: PolicyStatus | "all";
  variables?: any;
}) => {
  const { data, loading, refetch } = useQuery<PoliciesCollectionData>(
    policiesQueryDocument,
    {
      fetchPolicy: "network-only",
      variables: variables,
      skip: !user?.accessToken || !user?.client || !user?.id,
      context: {
        headers: {
          "access-token": user?.accessToken,
          client: user?.client,
          uid: user?.id,
        },
      },
    }
  );

  const dist = useMemo(() => {
    const dist = {
      approved: [] as PolicyData[],
      rejected: [] as PolicyData[],
      pending: [] as PolicyData[],
    };

    data?.policies?.collection?.forEach((p) => {
      switch (p.status) {
        case "rejected":
          dist.rejected.push(p);
          break;
        case "approved":
          dist.approved.push(p);
          break;
        case "pending":
        default:
          dist.pending.push(p);
          break;
      }
    });

    return dist;
  }, [data]);

  const policies = (() => {
    switch (policyStatus) {
      case "approved":
        return dist.approved;
      case "pending":
        return dist.pending;
      case "rejected":
        return dist.rejected;
      default:
        return data?.policies?.collection || [];
    }
  })();

  return {
    policies,
    dist: {
      all: data?.policies?.collection || [],
      ...dist,
    },
    loading,
    refetch,
    metadata: data?.policies?.metadata,
  };
};

export default usePrivatePoliciesCall;
