import { useMemo } from "react";
import { useQuery, gql } from "@apollo/client";
import { Practice } from "../../types";

export type PracticesCollectionData = {
  practices: { collection: Practice[]; metadata: { totalPages: 0 } };
};

const fetchPracticesQueryDocument = gql`
  query FetchPracticesQuery($limit: Int, $page: Int) {
    practices(limit: $limit, page: $page) {
      collection {
        country {
          name
        }
        createdAt
        date
        description
        document
        id
        link
        picture
        practiceCategory {
          name
        }
        status
        theme {
          id
          title
        }
        title
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

const usePrivatePracticesCall = (
  user: any,
  currTabIndex?: any,
  variables?: any
) => {
  const { data, loading } = useQuery<PracticesCollectionData>(
    fetchPracticesQueryDocument,
    {
      fetchPolicy: "cache-and-network",
      skip: !user?.accessToken || !user?.client || !user?.id,
      variables: variables || {},
      context: {
        headers: {
          "access-token": user?.accessToken,
          client: user?.client,
          uid: user?.id,
        },
      },
    }
  );

  const dist = useMemo(() => {
    const dist = {
      approved: [] as Practice[],
      rejected: [] as Practice[],
      pending: [] as Practice[],
    };

    data?.practices?.collection.forEach((p) => {
      switch (p.status) {
        case "rejected":
          dist.rejected.push(p);
          break;
        case "approved":
          dist.approved.push(p);
          break;
        case "pending":
        default:
          dist.pending.push(p);
          break;
      }
    });

    return dist;
  }, [data]);

  const practices = (() => {
    switch (currTabIndex) {
      case "approved":
        return dist.approved;
      case "pending":
        return dist.pending;
      case "rejected":
        return dist.rejected;
      case "all":
      default:
        return data?.practices?.collection || [];
    }
  })();

  return {
    practices,
    dist: {
      all: data?.practices?.collection || [],
      ...dist,
    },
    loading,
    metadata: data?.practices?.metadata,
  };
};

export { usePrivatePracticesCall };
