const chartTypes = [
  {
    value: "column",
    label: "Column Chart",
  },
  {
    value: "line",
    label: "Line Chart",
  },
  {
    value: "pie",
    label: "Pie Chart",
  },
];

export { chartTypes };
