"use client";

import { useState } from "react";
import { DayPicker } from "react-day-picker";
import { ConditionalRenderer } from "../../conditionalRenderer";
import { Button } from "@mui/material";
import "react-day-picker/style.css";
import "./styles.css";

const formatDateRange = (from: Date | null, to: Date | null) => {
  if (from && to) {
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const formattedFrom = from.toLocaleDateString("en-US", options);
    const formattedTo = to.toLocaleDateString("en-US", options);
    return `${formattedFrom} - ${formattedTo}`;
  }
  return "Date range";
};

function SingleInputDateRangePicker({ styling }: any) {
  const [range, setRange] = useState({ from: null, to: null });
  const [isOpen, setIsOpen] = useState(false); // Manage calendar visibility

  const handleDayClick = (day: any) => {
    if (!range.from || (range.from && range.to)) {
      setRange({ from: day, to: null });
    } else {
      setRange((prev) => ({ ...prev, to: day }));
      setIsOpen(false);
    }
  };

  const modifiers = {
    start: range.from,
    end: range.to,
  };

  return (
    <>
      <Button sx={styling} onClick={() => setIsOpen((prev) => !prev)}>
        {formatDateRange(range.from, range.to)}
      </Button>
      <ConditionalRenderer condition={isOpen}>
        <DayPicker
          numberOfMonths={2}
          pagedNavigation
          className="DayPicker"
          mode="range"
          onDayClick={handleDayClick}
        />
      </ConditionalRenderer>
    </>
  );
}

export { SingleInputDateRangePicker };

{
  /* <div className="rangePickerWrapper">
  <Datepicker
  toggleClassName="datepicker calender-bg rounded-r-[5px] text-white right-0 h-full px-3"
  value={value}
  onChange={handleValueChange}
  showShortcuts={false}
  placeholder="Select a date range"
  separator=" to "
  />
  </div> */
}
{
  /* <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DemoContainer components={['SingleInputDateRangeField']}>
    <DateRangePicker
className="dateRangePicker"
sx={styling}
slots={{ field: SingleInputDateRangeField }}
name="allowedRange"
/>
</DemoContainer>
</LocalizationProvider> */
}
// import { DateRangePicker } from "@mui/x-date-pickers-pro";
// import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
// import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import Datepicker from "react-tailwindcss-datepicker";
// const [value, setValue] = useState({
//   startDate: null,
//   endDate: null,
// });

// const handleValueChange = (selectedRange: any) => {
//   setValue(selectedRange);
// };
