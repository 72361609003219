import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiSelect from "@mui/material/Select";
import { gql, useQuery } from "@apollo/client";
import { useState } from "react";

const Select = styled(MuiSelect)(({ theme }) => ({
  borderRadius: 100,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[4],
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

const policyFormOptionsQueryDocument = gql`
  query policyFormOptionsQuery {
    countryOptions: countries {
      value: id
      label: name
    }
    themeOptions: themes {
      value: id
      label: title
    }
  }
`;
function Filters({ setFilters, filters }: any) {
  const { data, loading } = useQuery<any>(policyFormOptionsQueryDocument);

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from(
    { length: currentYear - 2000 + 1 },
    (_, i) => currentYear - i
  );

  const handleChange = (event: any) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  return (
    <Container sx={{ my: 5 }}>
      <Stack direction="row" gap={2} alignItems="center">
        <Typography variant="body2" color="textSecondary">
          Filter by:
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Country</InputLabel>
            <Select label="Country" name="country" onChange={handleChange}>
              {data?.countryOptions.map((op: any, i: any) => (
                <MenuItem key={`${op.value}-${i}`} value={op.value}>
                  {op.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Period</InputLabel>
            <Select
              label="Period"
              name="period"
              onChange={handleChange}
              value={filters?.period}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250,
                    overflow: "auto",
                  },
                },
              }}
            >
              {yearOptions.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Theme</InputLabel>
            <Select label="Theme" name="theme" onChange={handleChange}>
              {data?.themeOptions.map((op: any, i: any) => (
                <MenuItem key={`${op.value}-${i}`} value={op.value}>
                  {op.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
    </Container>
  );
}
export { Filters };
