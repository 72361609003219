
import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import AppBar from '../@lib/components/@common/AppBar';
import PageTitle from '../@lib/components/@common/PageTitle';

const Support:React.FC = () => {
  return (
    <Box minHeight={610}>
      <AppBar>
        <PageTitle
          title="Support"
        />
      </AppBar>
      <Container>
        <Typography variant="overline">This page is under development</Typography>
      </Container>
    </Box>
  );
};

export default Support;
