function documentsDateConversion(date: any) {
  const dateArray = date?.split("T");
  const modifiedDateCreatedAt = dateArray?.[0]
    ?.split("-")
    ?.reverse()
    ?.join("-");

  return modifiedDateCreatedAt || "";
}

export {documentsDateConversion}